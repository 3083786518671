import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const toast = useToast();

  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      toast({
        title: "Epost sendt",
        description: "Sjekk innboksen din for å kunne nullstille passordet",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      toast({
        title: "Feil ved sending",
        description:
          "Det skjedde en feil når vi skulle sende epost, vennligst prøv igjen",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Box as="section" bg="gray.800" py="8">
        <Container maxW="md" py={{ base: "12", md: "24" }}>
          <Stack spacing="8">
            <Stack spacing="6">
              <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
                <Heading color="white" size={{ base: "xs", md: "sm" }}>
                  Nullstill passord
                </Heading>
                <Text color="whiteAlpha.800">
                  Skriv inn eposten din for å nullstille passordet
                </Text>
              </Stack>
            </Stack>
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email" color="whiteAlpha.700">
                    Epost
                  </FormLabel>
                  <Input
                    id="email"
                    placeholder="Skriv inn din epost"
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormControl>
              </Stack>
              <Stack spacing="4">
                <Button variant="primary" onClick={handleResetPassword}>
                  Send nytt passord
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box as="section" bg="gray.800" py="40"></Box>
      <Box as="section" bg="gray.800" py="40"></Box>
      <Box as="section" bg="gray.800" py="40"></Box>
    </>
  );
};

export default ResetPassword;
