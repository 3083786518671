import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Button,
  HStack,
  Icon,
  Avatar,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  useToast,
  Checkbox,
  AlertDialog,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogFooter,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiList } from "react-icons/fi";
import { IoArrowDown, IoArrowUp } from "react-icons/io5";
import { FaPlusCircle } from "react-icons/fa";

import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import Select from "react-select";

import { Summary } from "./Summary";
import { stockOptions } from "./data/stockOptions";
import usePopulatePortfolioContext from "./usePopulatePortfolioContext";

// --- TYPES ---
export type PortfolioStock = {
  name: string;
  ticker: string;
  shares: number;
  dividend: number;
  sharePrice: number;
  costPrice: number;
  dividendYield: number;
  dividendIncome: number;
  stockValue: number;
  initialInvestment: number;
  totalReturn: number;
};

export type WishlistStock = {
  name: string;
  ticker: string;
  dividend: number;
  sharePrice: number;
  dividendYield: number;
  rating: number;
};

export type StockOption = { value: string; label: string };

const initialFormValues = {
  name: "",
  ticker: "",
  shares: 0,
  dividend: 0,
  sharePrice: 0,
  costPrice: 0,
  dividendYield: 0,
  dividendIncome: 0,
  stockValue: 0,
  initialInvestment: 0,
  totalReturn: 0,
};

const Portfolio = () => {
  // --- PORTFOLIO STATE ---
  const [portfolioStocks, setPortfolioStocks] = useState<PortfolioStock[]>(
    () => {
      const storedStocks = localStorage.getItem("portfolioStocks");
      return storedStocks ? JSON.parse(storedStocks) : [];
    }
  );

  const [selectedStockOption, setSelectedStockOption] =
    useState<StockOption | null>(null);

  // --- WISHLIST STATE ---
  const [wishlistStocks, setWishlistStocks] = useState<PortfolioStock[]>(() => {
    const storedStocks = localStorage.getItem("wishlistStocks");
    return storedStocks ? JSON.parse(storedStocks) : [];
  });

  // --- SORTING STATE ---
  const [sortBy, setSortBy] = useState<keyof PortfolioStock>("name");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  // --- FORM STATE ---
  const [form, setForm] = useState(initialFormValues);

  // --- EDITING STATE ---
  const [isEditing, setIsEditing] = useState(false);
  const [editingSymbol, setEditingSymbol] = useState("");

  // --- SEARCH STATE ---
  const [searchQuery, setSearchQuery] = useState("");
  const [totalStocks, setTotalStocks] = useState(0);
  const [matchCount, setMatchCount] = useState(portfolioStocks.length);

  // --- SUMMARY STATE ---
  const [totalDividendIncome, setTotalDividendIncome] = useState(0);
  const [totalStockValue, setTotalStockValue] = useState(0);
  const [averageYield, setAverageYield] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);

  // --- MODAL STATE ---
  const { isOpen, onOpen, onClose } = useDisclosure();
  const openDeleteAlert = useDisclosure();

  // --- REF STATE ---
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const cancelRef = useRef(null);

  // --- TOAST STATE ---
  const setToast = useToast();
  const [deletedTickers, setDeletedTickers] = useState<string[]>([]);
  const [isAddStockToastOpen, setIsAddStockToastOpen] = useState(false);

  // --- TABLE CHECKBOX STATE ---
  const [checkedItems, setCheckedItems] = useState(new Set<string>());

  // --- DELETE STOCK STATE ---
  const [isDeleting, setIsDeleting] = useState(false);

  // --- FORMATING DATA ---

  // Split numbers in thousands
  const formatNumber = (value: number) => {
    const formattedValue = value
      .toLocaleString("en-US", { useGrouping: true, maximumFractionDigits: 0 })
      .replace(/,/g, " ");
    return `${formattedValue}`;
  };

  // --- UPDATE PORTFOLIOS ---

  // Set stocks in portfolio and wishlist
  useEffect(() => {
    localStorage.setItem("portfolioStocks", JSON.stringify(portfolioStocks));
    localStorage.setItem("wishlistStocks", JSON.stringify(wishlistStocks));
  }, [portfolioStocks, wishlistStocks]);

  // Populate portfolio context
  usePopulatePortfolioContext(portfolioStocks);

  // --- SUMMARY ---

  // Summarize portfolio data
  useEffect(() => {
    let newTotalDividendIncome = 0;
    let newTotalStockValue = 0;
    let newAverageYield = 0;
    let newTotalReturn = 0;

    // Total value of stocks that have a dividend
    let divisor = 0;

    portfolioStocks.forEach((stock) => {
      newTotalDividendIncome += stock.dividend * stock.shares;
      newTotalStockValue += stock.sharePrice * stock.shares;
      if (stock.initialInvestment > 0) {
        newTotalReturn += stock.stockValue - stock.initialInvestment;
      }

      if (stock.dividend) {
        divisor += stock.sharePrice * stock.shares;
      }
    });

    if (divisor > 0) {
      newAverageYield = (newTotalDividendIncome / divisor) * 100;
    }

    setTotalDividendIncome(newTotalDividendIncome);
    setTotalStockValue(newTotalStockValue);
    setAverageYield(newAverageYield);
    setTotalReturn(newTotalReturn);
  }, [portfolioStocks]);

  // --- SEARCH ---

  // Count the number of matches in a search
  useEffect(() => {
    const filteredStocks = portfolioStocks.filter(
      (stock) =>
        stock.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        stock.ticker.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setMatchCount(filteredStocks.length);
  }, [searchQuery, portfolioStocks, matchCount]);

  // --- CALCULATIONS ---
  const calculateYield = (stock: PortfolioStock) => {
    if (!stock.dividend || !stock.sharePrice) {
      return 0;
    }
    const yieldValue = (stock.dividend / stock.sharePrice) * 100;
    return yieldValue.toFixed(2);
  };

  const calculateDividendIncome = (stock: PortfolioStock) => {
    if (!stock.dividend || !stock.shares) {
      return 0;
    }
    const dividendIncome = stock.dividend * stock.shares;
    return formatNumber(dividendIncome);
  };

  const calculateStockValue = (stock: PortfolioStock) => {
    if (!stock.sharePrice || !stock.shares) {
      return 0;
    }
    const stockValue = stock.sharePrice * stock.shares;
    return formatNumber(stockValue);
  };

  const calculateInitialInvestment = (stock: PortfolioStock) => {
    if (!stock.costPrice || !stock.shares) {
      return 0;
    }
    const initialInvestment = stock.costPrice * stock.shares;
    return formatNumber(initialInvestment);
  };

  const calculateTotalReturn = (stock: PortfolioStock) => {
    if (!stock.stockValue || !stock.initialInvestment) {
      return 0;
    }
    const totalReturn = stock.stockValue - stock.initialInvestment;
    return formatNumber(totalReturn);
  };

  // --- CHECKBOX FUNCTIONS ---
  const allChecked =
    portfolioStocks.length > 0 && checkedItems.size === portfolioStocks.length;

  const isIndeterminate =
    checkedItems.size > 0 && checkedItems.size < portfolioStocks.length;

  // --- HANDLERS ---

  // Move selected stocks to wishlist
  const handleMoveToWishlist = () => {
    const selectedStocks = portfolioStocks.filter((stock) =>
      checkedItems.has(stock.ticker)
    );

    if (selectedStocks.length === 0) {
      return;
    }

    const newPortfolioStocks = portfolioStocks.filter(
      (stock) => !checkedItems.has(stock.ticker)
    );

    const stocksToMove = selectedStocks.filter((stock) => {
      const isStockInWishlist = wishlistStocks.some(
        (wishlistStock) => wishlistStock.ticker === stock.ticker
      );
      return !isStockInWishlist;
    });

    const newWishlistStocks = [...wishlistStocks, ...stocksToMove];

    setPortfolioStocks(newPortfolioStocks);
    setWishlistStocks(newWishlistStocks);
    setCheckedItems(new Set<string>());
    setToast({
      title: `${selectedStocks.length} aksje${
        selectedStocks.length === 1 ? "" : "r"
      } flyttet til ønskelisten!`,
      status: "success",
      isClosable: true,
    });
  };

  // Search for a stock in the dropdown
  const handleSearch = (search: string) => {
    setSearchQuery(search);
    const filteredStocks = portfolioStocks.filter(
      (stock) =>
        stock.name.toLowerCase().includes(search.toLowerCase()) ||
        stock.ticker.toLowerCase().includes(search.toLowerCase())
    );
    setMatchCount(filteredStocks.length);
  };

  // Saves the stock a user selects temporary
  const handleSelectStock = (selectedOption: any) => {
    setSelectedStockOption(selectedOption);
    setForm({
      name: selectedOption.label,
      ticker: selectedOption.value,
      shares: 0,
      dividend: 0,
      sharePrice: 0,
      costPrice: 0,
      dividendYield: 0,
      dividendIncome: 0,
      stockValue: 0,
      initialInvestment: 0,
      totalReturn: 0,
    });
  };

  // Add a stock from the dropdown to the portfolio
  const handleAddStock = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!selectedStockOption) {
      if (!isAddStockToastOpen) {
        setIsAddStockToastOpen(true);
        setToast({
          title: "Vennligst velg en aksje",
          status: "warning",
          isClosable: true,
          onCloseComplete: () => setIsAddStockToastOpen(false),
        });
      }
      return;
    }
    setPortfolioStocks([
      ...portfolioStocks,
      {
        name: form.name,
        ticker: selectedStockOption.value,
        shares: form.shares,
        dividend: form.dividend,
        sharePrice: form.sharePrice,
        costPrice: form.costPrice,
        dividendYield: form.dividend / form.sharePrice,
        dividendIncome: form.dividend * form.shares,
        stockValue: form.sharePrice * form.shares,
        initialInvestment: form.costPrice * form.shares,
        totalReturn: form.stockValue - form.initialInvestment,
      },
    ]);
    setForm(initialFormValues);
    setTotalStocks(totalStocks + 1);
    setSelectedStockOption(null);
    setToast({
      title: `${form.name} ble lagt til`,
      status: "success",
      isClosable: true,
    });
  };

  // Delete a stock from the portfolio
  const handleDeleteStock = (ticker: string, showToast: boolean) => {
    setPortfolioStocks((prevStocks) =>
      prevStocks.filter((stock) => stock.ticker !== ticker)
    );
    setTotalStocks(totalStocks - 1);

    if (showToast) {
      setDeletedTickers((prevTickers) => [...prevTickers, ticker]);
    }
  };

  // Edit a stock in the portfolio
  const handleEditStock = (ticker: string) => {
    onOpen();
    setIsEditing(true);
    setEditingSymbol(ticker);
    const stock = portfolioStocks.find((stock) => stock.ticker === ticker);
    if (!stock) {
      return;
    }
    const stockOption = stockOptions.find(
      (option) => option.value === stock.ticker
    );
    if (!stockOption) {
      return;
    }
    setForm(stock);
    setSelectedStockOption(stockOption);
  };

  // Submit a stock that has been edited
  const handleSubmitEdit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Check for empty or invalid number fields and set them to zero
    const sanitizedForm: PortfolioStock = {
      ...form,
      shares: isNaN(form.shares) ? 0 : form.shares,
      dividend: isNaN(form.dividend) ? 0 : form.dividend,
      sharePrice: isNaN(form.sharePrice) ? 0 : form.sharePrice,
      costPrice: isNaN(form.costPrice) ? 0 : form.costPrice,
    };

    setPortfolioStocks((prevStocks) =>
      prevStocks.map((stock) => {
        if (stock.ticker === editingSymbol) {
          return {
            ...sanitizedForm,
            dividendYield: sanitizedForm.dividend / sanitizedForm.sharePrice,
            dividendIncome: sanitizedForm.dividend * sanitizedForm.shares,
            stockValue: sanitizedForm.sharePrice * sanitizedForm.shares,
            initialInvestment: sanitizedForm.costPrice * sanitizedForm.shares,
            totalReturn:
              sanitizedForm.stockValue - sanitizedForm.initialInvestment,
          };
        }
        return stock;
      })
    );
    localStorage.setItem(
      "portfolioStocks",
      JSON.stringify([...portfolioStocks])
    );
    setForm(initialFormValues);
    setIsEditing(false);
    setEditingSymbol("");
    onClose();
    setToast({
      title: `${form.name} ble endret`,
      status: "success",
      isClosable: true,
    });
    setSelectedStockOption(null);
  };

  // Close modal when cancelling adding or editing
  const handleModalClose = () => {
    setForm(initialFormValues);
    setIsEditing(false);
    setEditingSymbol("");
    setSelectedStockOption(null);
    onClose();
    openDeleteAlert.onClose();
  };

  // Sort table ascending or descending
  const handleSort = (column: keyof PortfolioStock) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortDirection("asc");
    }
  };

  // Show icon when sorting the table
  const handleSortIcon = (column: string) => {
    if (sortBy !== column) {
      return null;
    }
    if (sortBy === "name") {
      return sortDirection === "asc" ? (
        <Icon as={IoArrowDown} color="muted" boxSize="4" />
      ) : (
        <Icon as={IoArrowUp} color="muted" boxSize="4" />
      );
    }
    return sortDirection === "asc" ? (
      <Icon as={IoArrowUp} color="muted" boxSize="4" />
    ) : (
      <Icon as={IoArrowDown} color="muted" boxSize="4" />
    );
  };

  // Checkbox for table header
  const handleTableHeaderCheckbox = () => {
    if (allChecked) {
      setCheckedItems(new Set());
    } else {
      const newCheckedItems = new Set(
        sortedStocks.map((stock) => stock.ticker)
      );
      setCheckedItems(newCheckedItems);
    }
  };

  // Checkbox for table data
  const handleTableDataChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    ticker: string
  ) => {
    const isChecked = event.target.checked;
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = new Set(prevCheckedItems);
      if (isChecked) {
        newCheckedItems.add(ticker);
      } else {
        newCheckedItems.delete(ticker);
      }
      return newCheckedItems;
    });
  };

  // Delete all checked stocks
  const handleDeleteAlert = () => {
    setIsDeleting(true);
    openDeleteAlert.onOpen();
  };

  // Delete all checked stocks
  const handleDeleteSelected = () => {
    const tickersDeleted: string[] = [];
    checkedItems.forEach((ticker) => {
      handleDeleteStock(ticker, false);
      tickersDeleted.push(ticker);
    });
    setCheckedItems(new Set());
    openDeleteAlert.onClose();

    if (tickersDeleted.length > 0) {
      const newDeletedTickers = [...deletedTickers, ...tickersDeleted];
      setDeletedTickers(newDeletedTickers);

      const message =
        tickersDeleted.length === 1
          ? `${tickersDeleted[0]} ble slettet!`
          : `${tickersDeleted.length} aksjer slettet (${tickersDeleted.join(
              ", "
            )})`;

      setToast({
        title: message,
        status: "success",
        isClosable: true,
      });
    }
  };

  // Calculate columns for yield, income, value, investment and returns in the table
  const stocksWithValues: PortfolioStock[] = portfolioStocks.map((stock) => {
    return {
      ...stock,
      dividendYield: stock.dividend / stock.sharePrice,
      dividendIncome: stock.dividend * stock.shares,
      stockValue: stock.sharePrice * stock.shares,
      initialInvestment: stock.costPrice * stock.shares,
      totalReturn: stock.stockValue - stock.initialInvestment,
    };
  });

  // Filter stocks when searching
  const filteredStocks: PortfolioStock[] = useMemo(() => {
    return stocksWithValues.filter(
      (stock) =>
        stock.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        stock.ticker.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [stocksWithValues, searchQuery]);

  // Show stocks in the dropdown that are not in portfolio already
  const filteredOptions = stockOptions.filter(
    (option) => !portfolioStocks.find((stock) => stock.ticker === option.value)
  );

  // Sort stocks in the table columns
  const sortedStocks: PortfolioStock[] = useMemo(() => {
    return filteredStocks.sort((a, b) => {
      if (sortDirection === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      }
      return a[sortBy] < b[sortBy] ? 1 : -1;
    });
  }, [filteredStocks, sortBy, sortDirection]);

  // Show the number of checked items, search matches or number of stocks in portfolio
  const showTableCount = () => {
    if (checkedItems.size > 0) {
      return `${Array.from(checkedItems).length} aksje${
        checkedItems.size === 1 ? "" : "r"
      } valgt`;
    } else if (searchQuery) {
      return `Viser ${matchCount} av ${portfolioStocks.length} aksje${
        portfolioStocks.length === 1 ? "" : "r"
      }`;
    } else {
      return `${portfolioStocks.length} aksje${
        portfolioStocks.length === 1 ? "" : "r"
      }`;
    }
  };

  return (
    <Stack spacing={{ base: "6", lg: "6" }}>
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
      >
        <Stack spacing="1">
          <Heading size={{ base: "md", lg: "md" }} fontWeight="bold">
            Portefølje
          </Heading>
        </Stack>
      </Stack>
      <Stack spacing={{ base: "5", lg: "6" }}>
        <SimpleGrid columns={{ base: 4, md: 4 }} gap={{ base: "5", md: "6" }}>
          <Summary label={"Yield"} value={averageYield.toFixed(2) + " %"} />
          <Summary
            label={"Årlig inntekt"}
            value={formatNumber(totalDividendIncome) + " kr"}
          />
          <Summary
            label={"Porteføljeverdi"}
            value={formatNumber(totalStockValue) + " kr"}
          />
          <Summary
            label={"Avkastning"}
            value={formatNumber(totalReturn) + " kr"}
          />
        </SimpleGrid>
      </Stack>
      <Box px={{ base: "4", md: "6" }} pt="5">
        <Stack direction={{ md: "row" }} justify="space-between">
          {"Søk etter aksjer vises kun dersom det finnes aksjer i porteføljen"}
          {portfolioStocks.length !== 0 && (
            <InputGroup maxW="xs">
              <InputLeftElement pointerEvents="none">
                <Icon as={FiSearch} color="muted" boxSize="5" />
              </InputLeftElement>
              <Input
                placeholder="Søk etter aksjer i porteføljen"
                type="text"
                id="search"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </InputGroup>
          )}

          {
            "Dersom man endrer på en aksje vises form med inputfelter med eksisterende verdier. Hvis ikke vises nedtrekksmenyen hvor man kan legge til nye aksjer."
          }
          {isEditing ? (
            <Modal
              initialFocusRef={initialRef}
              finalFocusRef={finalRef}
              isOpen={isOpen}
              onClose={onClose}
              closeOnOverlayClick={false}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <form onSubmit={handleSubmitEdit}>
                  <ModalHeader>{form.name}</ModalHeader>
                  <ModalCloseButton onClick={handleModalClose} />
                  <ModalBody pb={6}>
                    <FormControl>
                      <FormLabel>Antall aksjer </FormLabel>
                      <NumberInput
                        ref={initialRef}
                        placeholder="Hvor mange aksjer har du?"
                        defaultValue={form.shares}
                        precision={0}
                        onChange={(value) =>
                          setForm({
                            ...form,
                            shares: parseFloat(value),
                          })
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Utbytte pr aksje</FormLabel>
                      <NumberInput
                        placeholder="Hvor mye betaler selskapet pr aksje?"
                        defaultValue={form.dividend}
                        precision={2}
                        onChange={(value) =>
                          setForm({
                            ...form,
                            dividend: parseFloat(value),
                          })
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Aksjekurs</FormLabel>
                      <NumberInput
                        placeholder="Hva står aksjekursen i nå?"
                        defaultValue={form.sharePrice}
                        precision={2}
                        onChange={(value) =>
                          setForm({
                            ...form,
                            sharePrice: parseFloat(value),
                          })
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Snittpris</FormLabel>
                      <NumberInput
                        placeholder="Hva har du betalt pr aksje?"
                        defaultValue={form.costPrice}
                        precision={2}
                        onChange={(value) =>
                          setForm({
                            ...form,
                            costPrice: parseFloat(value),
                          })
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button type="submit" colorScheme="blue" mr={3}>
                      Lagre
                    </Button>
                    <Button onClick={handleModalClose}>Avbryt</Button>
                  </ModalFooter>
                </form>
              </ModalContent>
            </Modal>
          ) : (
            <Modal onClose={onClose} isOpen={isOpen} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Velg en aksje</ModalHeader>
                <ModalCloseButton onClick={handleModalClose} />
                <ModalBody>
                  <form onSubmit={handleAddStock}>
                    <Stack width={"sm"}>
                      <Select
                        options={filteredOptions}
                        onChange={handleSelectStock}
                        value={selectedStockOption}
                        autoFocus={true}
                      />
                      <Button type="submit" colorScheme="blue">
                        Legg til
                      </Button>
                    </Stack>
                  </form>
                </ModalBody>
                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>
          )}

          {
            "Dersom brukeren har valg å slette en aksje åpnes modal for å få bekreftelse"
          }
          {isDeleting && (
            <AlertDialog
              isOpen={openDeleteAlert.isOpen}
              leastDestructiveRef={cancelRef}
              onClose={handleModalClose}
              isCentered
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Slett aksje{checkedItems.size > 1 ? "r" : ""}
                  </AlertDialogHeader>
                  <AlertDialogBody>
                    Er du sikker på at du vil slette aksjen
                    {checkedItems.size > 1 ? "e" : ""} du har valgt?
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={openDeleteAlert.onClose}>
                      Avbryt
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={handleDeleteSelected}
                      ml={3}
                    >
                      Slett
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          )}

          {"Dersom checkbox er aktivert vises knapp for å slette"}
          {checkedItems.size > 0 && (
            <HStack>
              <Button
                variant="outline"
                color="red"
                leftIcon={<FiTrash2 fontSize="1.25rem" />}
                onClick={handleDeleteAlert}
                disabled={checkedItems.size === 0}
              >
                Slett
              </Button>
            </HStack>
          )}

          {
            "Dersom checkbox er aktivert vises knapp for å flytte aksje til ønskeliste"
          }
          {checkedItems.size > 0 && (
            <HStack>
              <Button
                variant="outline"
                leftIcon={<FiList fontSize="1.25rem" />}
                onClick={handleMoveToWishlist}
                disabled={checkedItems.size === 0}
              >
                Flytt til ønskeliste
              </Button>
            </HStack>
          )}

          {
            "Dersom én enkelt checkbox er aktivert vises knapp for å endre aksje"
          }
          {checkedItems.size === 1 && (
            <HStack>
              <Button
                variant="outline"
                leftIcon={<FiEdit2 fontSize="1.25rem" />}
                onClick={() =>
                  handleEditStock(checkedItems.values().next().value)
                }
                disabled={checkedItems.size !== 1}
              >
                Endre
              </Button>
            </HStack>
          )}

          {"Dersom porteføljen er tom vises knappen for å legge til aksjer"}
          {portfolioStocks.length > 0 && (
            <HStack>
              <Button
                variant="primary"
                leftIcon={<FaPlusCircle fontSize="1.25rem" />}
                onClick={onOpen}
              >
                Legg til
              </Button>
            </HStack>
          )}
        </Stack>
      </Box>

      {
        "Dersom porteføljen er tom vises en banner, hvis ikke vises tabellen med aksjer"
      }
      {portfolioStocks.length === 0 ? (
        <Box
          bg="bg-surface"
          px={{ base: "4", md: "8" }}
          py="5"
          boxShadow="sm"
          borderRadius="lg"
        >
          <Stack
            spacing="4"
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
          >
            <Stack spacing="1">
              <Text fontSize="lg" fontWeight="medium">
                Porteføljen er tom
              </Text>
              <Text color="muted" fontSize="sm">
                Legg til aksjer i porteføljen
              </Text>
            </Stack>
            <Button
              variant="primary"
              leftIcon={<FaPlusCircle fontSize="1.25rem" />}
              onClick={onOpen}
            >
              Legg til
            </Button>
          </Stack>
        </Box>
      ) : (
        <Box overflowX="auto">
          <Table>
            <Thead>
              <Tr>
                <Th onClick={() => handleSort("name")}>
                  <HStack spacing="3">
                    <Checkbox
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={handleTableHeaderCheckbox}
                    />
                    <HStack spacing="1">
                      <Text>Navn</Text>
                      {handleSortIcon("name")}
                    </HStack>
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("shares")}>
                  <HStack spacing="1">
                    <Text>Aksjer</Text>
                    {handleSortIcon("shares")}
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("dividend")}>
                  {" "}
                  <HStack spacing="1">
                    <Text>Utbytte</Text>
                    {handleSortIcon("dividend")}
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("sharePrice")}>
                  {" "}
                  <HStack spacing="1">
                    <Text>Aksjekurs</Text>
                    {handleSortIcon("sharePrice")}
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("costPrice")}>
                  {" "}
                  <HStack spacing="1">
                    <Text>Snittpris</Text>
                    {handleSortIcon("costPrice")}
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("dividendYield")}>
                  {" "}
                  <HStack spacing="1">
                    <Text>Yield</Text>
                    {handleSortIcon("dividendYield")}
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("dividendIncome")}>
                  {" "}
                  <HStack spacing="1">
                    <Text>Inntekt</Text>
                    {handleSortIcon("dividendIncome")}
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("stockValue")}>
                  {" "}
                  <HStack spacing="1">
                    <Text>Verdi</Text>
                    {handleSortIcon("stockValue")}
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("initialInvestment")}>
                  {" "}
                  <HStack spacing="1">
                    <Text>Investert</Text>
                    {handleSortIcon("initialInvestment")}
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("totalReturn")}>
                  {" "}
                  <HStack spacing="1">
                    <Text>Avkastning</Text>
                    {handleSortIcon("totalReturn")}
                  </HStack>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedStocks.map((stock, index) => (
                <Tr key={index}>
                  <Td>
                    <HStack spacing="3">
                      <Checkbox
                        isChecked={checkedItems.has(stock.ticker)}
                        onChange={(e) => handleTableDataChange(e, stock.ticker)}
                      />
                      <Avatar
                        name={stock.name}
                        src={`../logos/${stock.ticker}.png`}
                        boxSize="10"
                      />
                      <Box>
                        <Text fontWeight="medium">{stock.name}</Text>
                        <Text color="muted">{stock.ticker}</Text>
                      </Box>
                    </HStack>
                  </Td>
                  <Td>
                    <Text color="muted">{formatNumber(stock.shares)}</Text>
                  </Td>
                  <Td>
                    <Text color="muted">{stock.dividend} kr</Text>
                  </Td>
                  <Td>
                    <Text color="muted">{stock.sharePrice} kr</Text>
                  </Td>
                  <Td>
                    <Text color="muted">{stock.costPrice} kr</Text>
                  </Td>
                  <Td>
                    <Text color="muted">{calculateYield(stock)} %</Text>
                  </Td>
                  <Td>
                    <Text color="muted">
                      {calculateDividendIncome(stock)} kr
                    </Text>
                  </Td>
                  <Td>
                    <Text color="muted">{calculateStockValue(stock)} kr</Text>
                  </Td>
                  <Td>
                    <Text color="muted">
                      {calculateInitialInvestment(stock)} kr
                    </Text>
                  </Td>
                  <Td>
                    <Text color="muted">{calculateTotalReturn(stock)} kr</Text>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      {
        "Dersom det er aksjer i porteføljen vises antall aksjer og eventuelle match for søk under tabellen"
      }
      {portfolioStocks.length !== 0 && (
        <Box px={{ base: "4", md: "6" }} pb="5">
          <HStack spacing="3" justify="space-between">
            <Text color="muted" fontSize="sm">
              {showTableCount()}
            </Text>
          </HStack>
        </Box>
      )}
    </Stack>
  );
};

export default Portfolio;
