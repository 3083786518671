// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBJrlOoY6Z4OUL2SN3hkRo3CMG5YWIMlkA",
  authDomain: "utbyttebanken.firebaseapp.com",
  databaseURL:
    "https://utbyttebanken-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "utbyttebanken",
  storageBucket: "utbyttebanken.appspot.com",
  messagingSenderId: "506644999700",
  appId: "1:506644999700:web:dbe2fe60ec8c02498e4101",
  measurementId: "G-1LH2LHX1E1",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);

// const db = getFirestore(app);

// // Get a list of cities from your database
// async function getCities(db) {
//   const citiesCol = collection(db, 'cities');
//   const citySnapshot = await getDocs(citiesCol);
//   const cityList = citySnapshot.docs.map(doc => doc.data());
//   return cityList;
// }
