import { Box, Stack, StackDivider, Switch, Text } from "@chakra-ui/react";
import { useState } from "react";

export const notifications = [
  {
    type: "Epost",
    description: "Motta epost med oppdateringer om dine aksjer",
    key: "email",
  },
];

const NotificationSettings = () => {
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(
    localStorage.getItem("emailNotificationsEnabled") === "true"
  );

  const handleEmailNotificationsEnabledChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const enabled = event.target.checked;
    setEmailNotificationsEnabled(enabled);
    localStorage.setItem("emailNotificationsEnabled", enabled.toString());
  };

  return (
    <Box
      bg="bg-surface"
      boxShadow="sm"
      borderRadius="lg"
      p={{ base: "4", md: "6" }}
    >
      <Stack spacing="5" divider={<StackDivider />}>
        <Stack spacing="1">
          <Text fontSize="xl" fontWeight="medium">
            Mine varsler
          </Text>
        </Stack>
        {notifications.map((notification) => (
          <Stack
            key={notification.key}
            justify="space-between"
            direction="row"
            spacing="4"
          >
            <Stack spacing="0.5" fontSize="sm">
              <Text color="emphasized" fontWeight="medium">
                {notification.type}
              </Text>
              <Text color="muted">{notification.description}</Text>
            </Stack>
            {notification.key === "email" && (
              <Switch
                isChecked={emailNotificationsEnabled}
                onChange={handleEmailNotificationsEnabledChange}
              />
            )}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default NotificationSettings;
