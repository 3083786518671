import {
  Box,
  Button,
  Container,
  Heading,
  Icon,
  Img,
  LightMode,
  SimpleGrid,
  Square,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  FiBell,
  FiPieChart,
  FiList,
  FiBarChart2,
  FiPercent,
  FiCalendar,
  FiGrid,
  FiBox,
  FiFile,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import Photo from "./Screenshot.png";

export const features = [
  {
    name: "Portefølje",
    description:
      "Få oversikt over din portefølje, sammenlign aksjer, se avkastning, inntekt ++",
    icon: FiGrid,
  },
  {
    name: "Ønskeliste",
    description:
      "Overvåk aksjer du ønsker å kjøpe, sammenlign og vurder om de er riktige for deg",
    icon: FiList,
  },
  {
    name: "Diversifisering",
    description:
      "Få oversikt over sammensetningen av porteføljen din, samt hvilke sektorer du har investert i",
    icon: FiPieChart,
  },
  {
    name: "Kalender",
    description:
      "Se når dine fremtidige utbytter blir utbetalt, og sammenlign månedlige utbetalinger",
    icon: FiCalendar,
  },
  {
    name: "Historikk",
    description:
      "Gå tilbake i tid, og se hvilke utbetalinger som har blitt gjort til deg de siste årene",
    icon: FiBarChart2,
  },
  {
    name: "Kalkulator",
    description: "Regn ut hvor mye du må investere for å oppnå ønsket inntekt",
    icon: FiPercent,
  },
  {
    name: "Varsling",
    description:
      "Få tilsendt oppdateringer når en aksje i porteføljen din utbetaler utbytte",
    icon: FiBell,
  },

  {
    name: "Importer data",
    description:
      "Ønsker du å importere data kan du laste opp hele porteføljen din i CSV format",
    icon: FiFile,
  },
  {
    name: "Flere verktøy",
    description:
      "Vi utvikler funksjonaliteten kontinuerlig, og ønsker gjerne forslag fra deg som investor",
    icon: FiBox,
  },
];

const LandingPage = () => {
  return (
    <Box>
      <Box as="section" bg="gray.800" pb={{ base: "12", md: "24" }}>
        <Box as="section" bg="gray.800" color="white" py="7.5rem">
          <Box
            maxW={{ base: "xl", md: "5xl" }}
            mx="auto"
            px={{ base: "6", md: "8" }}
          >
            <Box textAlign="center">
              <Heading
                as="h1"
                size="3xl"
                fontWeight="extrabold"
                maxW="48rem"
                mx="auto"
                lineHeight="1.2"
                letterSpacing="tight"
              >
                Portefølje for utbytteaksjer
              </Heading>
              <Text
                color="whiteAlpha.800"
                fontSize="xl"
                mt="4"
                maxW="xl"
                mx="auto"
              >
                Få oversikt over dine utbetalinger, inntekt, avkastning,
                diversifisering og mye mer
              </Text>
            </Box>
            <Stack
              justify="center"
              direction={{ base: "column", md: "row" }}
              mt="10"
              mb="20"
              spacing="4"
              align="center"
            >
              <LightMode>
                <Link to={"/signup"}>
                  <Button
                    size="lg"
                    colorScheme="blue"
                    px="8"
                    fontWeight="bold"
                    fontSize="md"
                  >
                    Kom i gang
                  </Button>
                </Link>
              </LightMode>
            </Stack>
            <Box
              className="group"
              cursor="pointer"
              position="relative"
              rounded="lg"
              overflow="hidden"
            >
              <Img alt="Screenshot of portfolio" src={Photo} />
            </Box>
          </Box>
        </Box>
        <Box as="section" bg="gray.800">
          <Container py={{ base: "16", md: "24" }}>
            <Stack spacing={{ base: "12", md: "16" }}>
              <Stack
                spacing={{ base: "4", md: "5" }}
                align="center"
                textAlign="center"
              >
                <Stack spacing="3">
                  <Heading color="white" size={{ base: "sm", md: "md" }}>
                    Verktøy
                  </Heading>
                </Stack>
                <Text
                  color="whiteAlpha.600"
                  fontSize={{ base: "lg", md: "xl" }}
                  maxW="3xl"
                >
                  Utbyttebanken gir deg tilgang til alt du trenger for å bygge
                  en solid utbytteportefølje.
                </Text>
              </Stack>
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                columnGap={8}
                rowGap={{ base: 10, md: 16 }}
              >
                {features.map((feature) => (
                  <Stack
                    key={feature.name}
                    spacing={{ base: "4", md: "5" }}
                    align="center"
                    textAlign="center"
                  >
                    <Square
                      size={{ base: "10", md: "12" }}
                      bg="blue.800"
                      color="inverted"
                      borderRadius="lg"
                    >
                      <Icon
                        as={feature.icon}
                        boxSize={{ base: "5", md: "6" }}
                      />
                    </Square>
                    <Stack spacing={{ base: "1", md: "2" }}>
                      <Text
                        fontSize={{ base: "lg", md: "xl" }}
                        fontWeight="medium"
                        color="whiteAlpha.800"
                      >
                        {feature.name}
                      </Text>
                      <Text color="whiteAlpha.600">{feature.description}</Text>
                    </Stack>
                  </Stack>
                ))}
              </SimpleGrid>
            </Stack>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPage;
