import { useContext, useEffect } from "react";
import { PortfolioStock } from "./Portfolio";
import { PortfolioContext } from "./PortfolioContext";

const usePopulatePortfolioContext = (portfolioStocks: PortfolioStock[]) => {
  const { setPortfolioStocks } = useContext(PortfolioContext);

  useEffect(() => {
    setPortfolioStocks(portfolioStocks);
  }, [portfolioStocks, setPortfolioStocks]);
};

export default usePopulatePortfolioContext;
