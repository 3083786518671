import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "./firebase";

interface Props {
  onLogin: () => void;
}

const Login = ({ onLogin }: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoginToastOpen, setIsLoginToastOpen] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const rememberMeValue = localStorage.getItem("rememberMe");
    if (rememberMeValue === "true") {
      setRememberMe(true);
      const savedEmail = localStorage.getItem("userEmail") || "";
      setEmail(savedEmail);
    }
  }, []);

  useEffect(() => {
    if (rememberMe) {
      localStorage.setItem("rememberMe", "true");
      localStorage.setItem("userEmail", email);
    } else {
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("userEmail");
    }
  }, [rememberMe, email, password]);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!email || !password) {
      if (!isLoginToastOpen) {
        setIsLoginToastOpen(true);
        toast({
          title: "Detaljer mangler",
          description: "Vennligst oppgi epost og passord for å logge inn",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {
            setIsLoginToastOpen(false);
          },
        });
      }
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);

      onAuthStateChanged(auth, (user) => {
        if (user) {
          onLogin();
          localStorage.setItem("user", JSON.stringify(user));
        }
      });

      if (rememberMe) {
        localStorage.setItem("rememberMe", "true");
        localStorage.setItem("userEmail", email);
      } else {
        localStorage.removeItem("rememberMe");
        localStorage.removeItem("userEmail");
      }

      navigate("/");
    } catch (error) {
      if (!isLoginToastOpen) {
        setIsLoginToastOpen(true);
        toast({
          title: "Feil brukernavn eller passord",
          description: "Du har oppgitt feil detaljer, vennligst prøv igjen",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {
            setIsLoginToastOpen(false);
          },
        });
      }
    }
  };

  return (
    <>
      <Box as="section" bg="gray.800" py="8">
        <Container maxW="md" py={{ base: "12", md: "24" }}>
          <Stack spacing="8">
            <Stack spacing="6">
              <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
                <Heading color="white" size={{ base: "xs", md: "sm" }}>
                  Logg inn
                </Heading>
                <Text color="whiteAlpha.800">
                  Du må logge på brukeren din for å bruke tjenesten
                </Text>
              </Stack>
            </Stack>
            <form onSubmit={handleLogin}>
              <Stack spacing="6">
                <Stack spacing="5">
                  <FormControl>
                    <FormLabel htmlFor="email" color="whiteAlpha.700">
                      Epost
                    </FormLabel>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Skriv inn eposten din"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="password" color="whiteAlpha.700">
                      Passord
                    </FormLabel>
                    <Input
                      id="password"
                      name="password"
                      placeholder="Skriv inn passordet ditt"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormControl>
                </Stack>
                <HStack justify="space-between">
                  <Checkbox
                    isChecked={rememberMe}
                    onChange={(e) => {
                      setRememberMe(e.target.checked);
                      if (e.target.checked && !email) {
                        const savedEmail =
                          localStorage.getItem("userEmail") || "";
                        setEmail(savedEmail);
                      }
                    }}
                  >
                    <Text fontSize="sm" color="whiteAlpha.800">
                      Husk meg
                    </Text>
                  </Checkbox>

                  <Button
                    variant="link"
                    colorScheme="whiteAlpha"
                    size="sm"
                    onClick={() => navigate("/reset-password")}
                  >
                    Glemt passord?
                  </Button>
                </HStack>
                <Stack spacing="4">
                  <Button type="submit" variant="primary">
                    Logg inn
                  </Button>
                </Stack>
              </Stack>
            </form>
            <VStack justify="center" spacing="4">
              <Link to={"/signup"}>
                <Text fontSize="md" color="whiteAlpha.800">
                  Har du ikke en konto?
                </Text>
              </Link>
            </VStack>
          </Stack>
        </Container>
      </Box>
      <Box as="section" bg="gray.800" py="40"></Box>
      <Box as="section" bg="gray.800" py="40"></Box>
    </>
  );
};

export default Login;
