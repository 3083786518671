import {
  Box,
  Heading,
  Stack,
  Text,
  Button,
  HStack,
  Icon,
  Avatar,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  useToast,
  Tooltip,
  Checkbox,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { FiEdit2, FiGrid, FiSearch, FiTrash2 } from "react-icons/fi";
import { IoArrowDown, IoArrowUp } from "react-icons/io5";
import { FaPlusCircle } from "react-icons/fa";

import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import Select from "react-select";

import { Rating } from "./Rating";
import { stockOptions } from "./data/stockOptions";

// --- TYPES ---
export type WishlistStock = {
  name: string;
  ticker: string;
  dividend: number;
  sharePrice: number;
  dividendYield: number;
  rating: number;
};

export type PortfolioStock = {
  name: string;
  ticker: string;
  shares?: number;
  dividend: number;
  sharePrice: number;
  dividendYield?: number;
  dividendIncome?: number;
  stockValue: number;
};

export type WishlistOption = { value: string; label: string };

const initialFormValues = {
  name: "",
  ticker: "",
  dividend: 0,
  sharePrice: 0,
  dividendYield: 0,
  rating: 0,
};

const Ønskeliste = () => {
  // --- WISHLIST STATE ---
  const [wishlistStocks, setWishlistStocks] = useState<WishlistStock[]>(() => {
    const storedWishlists = localStorage.getItem("wishlistStocks");
    return storedWishlists ? JSON.parse(storedWishlists) : [];
  });
  const [selectedWishlistOption, setSelectedWishlistOption] =
    useState<WishlistOption | null>(null);

  // --- PORTFOLIO STATE ---
  const [portfolioStocks, setPortfolioStocks] = useState<PortfolioStock[]>(
    () => {
      const storedStocks = localStorage.getItem("portfolioStocks");
      return storedStocks ? JSON.parse(storedStocks) : [];
    }
  );

  // --- SORTING STATE ---
  const [sortBy, setSortBy] = useState<keyof WishlistStock>("name");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  // --- FORM STATE ---
  const [form, setForm] = useState(initialFormValues);

  // --- EDITING STATE ---
  const [isEditing, setIsEditing] = useState(false);
  const [editingSymbol, setEditingSymbol] = useState("");

  // --- SEARCH STATE ---
  const [searchQuery, setSearchQuery] = useState("");
  const [totalWishlists, setTotalWishlists] = useState(0);
  const [matchCount, setMatchCount] = useState(wishlistStocks.length);

  // --- MODAL STATE ---
  const { isOpen, onOpen, onClose } = useDisclosure();
  const openDeleteAlert = useDisclosure();

  // --- REF STATE ---
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const cancelRef = useRef(null);

  // --- TOAST STATE ---
  const setToast = useToast();
  const [deletedTickers, setDeletedTickers] = useState<string[]>([]);
  const [isAddStockToastOpen, setIsAddStockToastOpen] = useState(false);

  // --- TABLE CHECKBOX STATE ---
  const [checkedItems, setCheckedItems] = useState(new Set<string>());

  // --- DELETE STOCK STATE ---
  const [isDeleting, setIsDeleting] = useState(false);

  // --- SEARCH ---

  // Count the number of matches in a search
  useEffect(() => {
    const filteredWishlists = wishlistStocks.filter(
      (wishlist) =>
        wishlist.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        wishlist.ticker.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setMatchCount(filteredWishlists.length);
  }, [searchQuery, wishlistStocks, matchCount]);

  // --- UPDATE PORTFOLIOS ---

  // Set stocks in portfolio and wishlist
  useEffect(() => {
    localStorage.setItem("portfolioStocks", JSON.stringify(portfolioStocks));
    localStorage.setItem("wishlistStocks", JSON.stringify(wishlistStocks));
  }, [portfolioStocks, wishlistStocks]);

  // --- CALCULATIONS ---
  const calculateYield = (wishlist: WishlistStock) => {
    if (!wishlist.dividend || !wishlist.sharePrice) {
      return 0;
    }
    const yieldValue = (wishlist.dividend / wishlist.sharePrice) * 100;
    return yieldValue.toFixed(2);
  };

  // --- CHECKBOX FUNCTIONS ---
  const allChecked =
    wishlistStocks.length > 0 && checkedItems.size === wishlistStocks.length;

  const isIndeterminate =
    checkedItems.size > 0 && checkedItems.size < wishlistStocks.length;

  // --- HANDLERS ---

  // Move stock from wishlist to portfolio
  const handleMoveToPortfolio = () => {
    const selectedStocks = wishlistStocks.filter((stock) =>
      checkedItems.has(stock.ticker)
    );

    if (selectedStocks.length === 0) {
      return;
    }

    const newWishlistStocks = wishlistStocks.filter(
      (stock) => !checkedItems.has(stock.ticker)
    );

    const stocksToMove = selectedStocks.filter((stock) => {
      const isStockInPortfolio = portfolioStocks.some(
        (portfolioStock) => portfolioStock.ticker === stock.ticker
      );
      return !isStockInPortfolio;
    });

    const newPortfolioStocks = [
      ...portfolioStocks,
      ...stocksToMove.map((stock) => {
        const { name, ticker, dividend, sharePrice } = stock;
        return {
          name,
          ticker,
          shares: 0,
          dividend,
          sharePrice,
          stockValue: 0,
          costPrice: 0,
        };
      }),
    ];

    setWishlistStocks(newWishlistStocks);
    setPortfolioStocks(newPortfolioStocks);
    setCheckedItems(new Set<string>());
    setToast({
      title: `${selectedStocks.length} aksje${
        selectedStocks.length === 1 ? "" : "r"
      } flyttet til porteføljen!`,
      status: "success",
      isClosable: true,
    });
  };

  // Search for a wishlist in the dropdown
  const handleSearch = (search: string) => {
    setSearchQuery(search);
    const filteredWishlists = wishlistStocks.filter(
      (wishlist) =>
        wishlist.name.toLowerCase().includes(search.toLowerCase()) ||
        wishlist.ticker.toLowerCase().includes(search.toLowerCase())
    );
    setMatchCount(filteredWishlists.length);
  };

  // Saves the wishlist a user selects temporary
  const handleSelectWishlist = (selectedOption: any) => {
    setSelectedWishlistOption(selectedOption);
    setForm({
      name: selectedOption.label,
      ticker: selectedOption.value,
      dividend: 0,
      sharePrice: 0,
      dividendYield: 0,
      rating: 0,
    });
  };

  // Add a wishlist from the dropdown to the portfolio
  const handleAddWishlist = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!selectedWishlistOption) {
      if (!isAddStockToastOpen) {
        setIsAddStockToastOpen(true);
        setToast({
          title: "Vennligst velg en aksje",
          status: "warning",
          isClosable: true,
          onCloseComplete: () => setIsAddStockToastOpen(false),
        });
      }
      return;
    }
    setWishlistStocks([
      ...wishlistStocks,
      {
        name: form.name,
        ticker: selectedWishlistOption.value,
        dividend: form.dividend,
        sharePrice: form.sharePrice,
        dividendYield: form.dividend / form.sharePrice,
        rating: 0,
      },
    ]);
    setForm(initialFormValues);
    setTotalWishlists(totalWishlists + 1);
    setSelectedWishlistOption(null);
    setToast({
      title: `${form.name} ble lagt til`,
      status: "success",
      isClosable: true,
    });
  };

  // Delete a wishlist from the portfolio
  const handleDeleteWishlist = (ticker: string, showToast: boolean) => {
    setWishlistStocks((prevWishlists) =>
      prevWishlists.filter((wishlist) => wishlist.ticker !== ticker)
    );
    setTotalWishlists(totalWishlists - 1);

    if (showToast) {
      setDeletedTickers((prevTickers) => [...prevTickers, ticker]);
    }
  };

  // Edit a wishlist in the portfolio
  const handleEditWishlist = (ticker: string) => {
    onOpen();
    setIsEditing(true);
    setEditingSymbol(ticker);
    const wishlist = wishlistStocks.find(
      (wishlist) => wishlist.ticker === ticker
    );
    if (!wishlist) {
      return;
    }
    const WishlistOption = stockOptions.find(
      (option) => option.value === wishlist.ticker
    );
    if (!WishlistOption) {
      return;
    }
    setForm(wishlist);
    setSelectedWishlistOption(WishlistOption);
  };

  // Submit a wishlist that has been edited
  const handleSubmitEdit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Check for empty or invalid number fields and set them to zero
    const sanitizedForm: WishlistStock = {
      ...form,
      dividend: isNaN(form.dividend) ? 0 : form.dividend,
      sharePrice: isNaN(form.sharePrice) ? 0 : form.sharePrice,
    };

    setWishlistStocks((prevWishlists) =>
      prevWishlists.map((wishlist) => {
        if (wishlist.ticker === editingSymbol) {
          return {
            ...sanitizedForm,
            dividendYield: sanitizedForm.dividend / sanitizedForm.sharePrice,
          };
        }
        return wishlist;
      })
    );
    setForm(initialFormValues);
    setIsEditing(false);
    setEditingSymbol("");
    onClose();
    setToast({
      title: `${form.name} ble endret`,
      status: "success",
      isClosable: true,
    });
    setSelectedWishlistOption(null);
  };

  // Close modal when cancelling adding or editing
  const handleModalClose = () => {
    setForm(initialFormValues);
    setIsEditing(false);
    setEditingSymbol("");
    setSelectedWishlistOption(null);
    onClose();
  };

  // Sort table ascending or descending
  const handleSort = (column: keyof WishlistStock) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortDirection("asc");
    }
  };

  // Show icon when sorting the table
  const handleSortIcon = (column: string) => {
    if (sortBy !== column) {
      return null;
    }
    if (sortBy === "name") {
      return sortDirection === "asc" ? (
        <Icon as={IoArrowDown} color="muted" boxSize="4" />
      ) : (
        <Icon as={IoArrowUp} color="muted" boxSize="4" />
      );
    }
    return sortDirection === "asc" ? (
      <Icon as={IoArrowUp} color="muted" boxSize="4" />
    ) : (
      <Icon as={IoArrowDown} color="muted" boxSize="4" />
    );
  };

  // Checkbox for table header
  const handleTableHeaderCheckbox = () => {
    if (allChecked) {
      setCheckedItems(new Set());
    } else {
      const newCheckedItems = new Set(
        wishlistStocks.map((stock) => stock.ticker)
      );
      setCheckedItems(newCheckedItems);
    }
  };

  // Checkbox for table data
  const handleTableDataChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    ticker: string
  ) => {
    const isChecked = event.target.checked;
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = new Set(prevCheckedItems);
      if (isChecked) {
        newCheckedItems.add(ticker);
      } else {
        newCheckedItems.delete(ticker);
      }
      return newCheckedItems;
    });
  };

  // Delete all checked stocks
  const handleDeleteAlert = () => {
    setIsDeleting(true);
    openDeleteAlert.onOpen();
  };

  // Delete all checked stocks
  const handleDeleteSelected = () => {
    const tickersDeleted: string[] = [];
    checkedItems.forEach((ticker) => {
      handleDeleteWishlist(ticker, false);
      tickersDeleted.push(ticker);
    });
    setCheckedItems(new Set());
    openDeleteAlert.onClose();

    if (tickersDeleted.length > 0) {
      const newDeletedTickers = [...deletedTickers, ...tickersDeleted];
      setDeletedTickers(newDeletedTickers);

      const message =
        tickersDeleted.length === 1
          ? `${tickersDeleted[0]} ble slettet!`
          : `${tickersDeleted.length} aksjer slettet (${tickersDeleted.join(
              ", "
            )})`;

      setToast({
        title: message,
        status: "success",
        isClosable: true,
      });
    }
  };

  // Handle rating a stock
  const handleRatingChange = (ticker: string, rating: number) => {
    const newWishlistStocks = [...wishlistStocks];
    const index = newWishlistStocks.findIndex(
      (stock) => stock.ticker === ticker
    );
    newWishlistStocks[index].rating = rating;
    setWishlistStocks(newWishlistStocks);
  };

  // --- FUNCTIONS ---

  // Calculate columns for yield, income and value in the table
  const wishlistsWithValues: WishlistStock[] = wishlistStocks.map(
    (wishlist) => {
      return {
        ...wishlist,
        dividendYield: wishlist.dividend / wishlist.sharePrice,
      };
    }
  );

  // Filter wishlists when searching
  const filteredWishlists: WishlistStock[] = useMemo(() => {
    return wishlistsWithValues.filter(
      (wishlist) =>
        wishlist.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        wishlist.ticker.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [wishlistsWithValues, searchQuery]);

  // Show wishlists in the dropdown that are not in portfolio already
  const filteredOptions = stockOptions.filter(
    (option) =>
      !wishlistStocks.find((wishlist) => wishlist.ticker === option.value)
  );

  // Sort wishlists in the table columns
  const sortedWishlists: WishlistStock[] = useMemo(() => {
    return filteredWishlists.sort((a, b) => {
      if (sortDirection === "asc") {
        return a[sortBy] > b[sortBy] ? 1 : -1;
      }
      return a[sortBy] < b[sortBy] ? 1 : -1;
    });
  }, [filteredWishlists, sortBy, sortDirection]);

  const showTableCount = () => {
    if (checkedItems.size > 0) {
      return `${Array.from(checkedItems).length} aksje${
        checkedItems.size === 1 ? "" : "r"
      } valgt`;
    } else if (searchQuery) {
      return `Viser ${matchCount} av ${wishlistStocks.length} aksje${
        wishlistStocks.length === 1 ? "" : "r"
      }`;
    } else {
      return `${wishlistStocks.length} aksje${
        wishlistStocks.length === 1 ? "" : "r"
      }`;
    }
  };

  return (
    <Stack spacing={{ base: "6", lg: "6" }}>
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
      >
        <Stack spacing="1">
          <Heading size={{ base: "md", lg: "md" }} fontWeight="bold">
            Ønskeliste
          </Heading>
        </Stack>
      </Stack>

      <Box px={{ base: "4", md: "6" }} pt="5">
        <Stack direction={{ md: "row" }} justify="space-between">
          {"Søk etter aksjer vises kun dersom det finnes aksjer i ønskelisten"}
          {wishlistStocks.length !== 0 && (
            <InputGroup maxW="xs">
              <InputLeftElement pointerEvents="none">
                <Icon as={FiSearch} color="muted" boxSize="5" />
              </InputLeftElement>
              <Input
                placeholder="Søk etter aksjer i ønskelisten"
                type="text"
                id="search"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </InputGroup>
          )}

          {
            "Dersom man endrer på en aksje vises form med inputfelter med eksisterende verdier. Hvis ikke vises nedtrekksmenyen hvor man kan legge til nye aksjer."
          }
          {isEditing ? (
            <Modal
              initialFocusRef={initialRef}
              finalFocusRef={finalRef}
              isOpen={isOpen}
              onClose={onClose}
              closeOnOverlayClick={false}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <form onSubmit={handleSubmitEdit}>
                  <ModalHeader>{form.name}</ModalHeader>
                  <ModalCloseButton onClick={handleModalClose} />
                  <ModalBody pb={6}>
                    <FormControl mt={4}>
                      <FormLabel>Utbytte pr aksje</FormLabel>
                      <Tooltip label="Utbytte pr aksje" fontSize="sm">
                        <Input
                          placeholder="Hvor mye betaler selskapet pr aksje?"
                          type="number"
                          value={form.dividend}
                          onChange={(event) =>
                            setForm({
                              ...form,
                              dividend: event.target.valueAsNumber,
                            })
                          }
                        />
                      </Tooltip>
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Aksjekurs</FormLabel>
                      <Tooltip label="Pris pr aksje" fontSize="sm">
                        <Input
                          placeholder="Hva står aksjekursen i nå?"
                          type="number"
                          value={form.sharePrice}
                          onChange={(event) =>
                            setForm({
                              ...form,
                              sharePrice: event.target.valueAsNumber,
                            })
                          }
                        />
                      </Tooltip>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button type="submit" colorScheme="blue" mr={3}>
                      Lagre
                    </Button>
                    <Button onClick={handleModalClose}>Avbryt</Button>
                  </ModalFooter>
                </form>
              </ModalContent>
            </Modal>
          ) : (
            <Modal onClose={onClose} isOpen={isOpen} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Velg en aksje</ModalHeader>
                <ModalCloseButton onClick={handleModalClose} />
                <ModalBody>
                  <form onSubmit={handleAddWishlist}>
                    <Stack width={"sm"}>
                      <Select
                        options={filteredOptions}
                        onChange={handleSelectWishlist}
                        value={selectedWishlistOption}
                        autoFocus={true}
                      />

                      <Button type="submit" colorScheme="blue">
                        Legg til
                      </Button>
                    </Stack>
                  </form>
                </ModalBody>
                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>
          )}

          {
            "Dersom brukeren har valg å slette en aksje åpnes modal for å få bekreftelse"
          }
          {isDeleting && (
            <AlertDialog
              isOpen={openDeleteAlert.isOpen}
              leastDestructiveRef={cancelRef}
              onClose={handleModalClose}
              isCentered
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Slett aksje{checkedItems.size > 1 ? "r" : ""}
                  </AlertDialogHeader>
                  <AlertDialogBody>
                    Er du sikker på at du vil slette aksjen
                    {checkedItems.size > 1 ? "e" : ""} du har valgt?
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={openDeleteAlert.onClose}>
                      Avbryt
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={handleDeleteSelected}
                      ml={3}
                    >
                      Slett
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          )}

          {"Dersom checkbox er aktivert vises knapp for å slette"}
          {checkedItems.size > 0 && (
            <HStack>
              <Button
                variant="outline"
                color="red"
                leftIcon={<FiTrash2 fontSize="1.25rem" />}
                onClick={handleDeleteAlert}
                disabled={checkedItems.size === 0}
              >
                Slett
              </Button>
            </HStack>
          )}

          {
            "Dersom checkbox er aktivert vises knapp for å flytte aksje til portefølje"
          }
          {checkedItems.size > 0 && (
            <HStack>
              <Button
                variant="outline"
                leftIcon={<FiGrid fontSize="1.25rem" />}
                onClick={handleMoveToPortfolio}
                disabled={checkedItems.size === 0}
              >
                Flytt til portefølje
              </Button>
            </HStack>
          )}

          {
            "Dersom én enkelt checkbox er aktivert vises knapp for å endre aksje"
          }
          {checkedItems.size === 1 && (
            <HStack>
              <Button
                variant="outline"
                leftIcon={<FiEdit2 fontSize="1.25rem" />}
                onClick={() =>
                  handleEditWishlist(checkedItems.values().next().value)
                }
                disabled={checkedItems.size !== 1}
              >
                Endre
              </Button>
            </HStack>
          )}

          {"Dersom ønskelisten er tom vises knappen for å legge til aksjer"}
          {wishlistStocks.length > 0 && (
            <HStack>
              <Button
                variant="primary"
                leftIcon={<FaPlusCircle fontSize="1.25rem" />}
                onClick={onOpen}
              >
                Legg til
              </Button>
            </HStack>
          )}
        </Stack>
      </Box>
      {
        "Dersom ønskelisten er tom vises en banner, hvis ikke vises tabellen med aksjer"
      }
      {wishlistStocks.length === 0 ? (
        <Box
          bg="bg-surface"
          px={{ base: "4", md: "8" }}
          py="5"
          boxShadow="sm"
          borderRadius="lg"
        >
          <Stack
            spacing="4"
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
          >
            <Stack spacing="1">
              <Text fontSize="lg" fontWeight="medium">
                Ønskelisten er tom
              </Text>
              <Text color="muted" fontSize="sm">
                Legg til aksjer i ønskelisten
              </Text>
            </Stack>
            <Button
              variant="primary"
              leftIcon={<FaPlusCircle fontSize="1.25rem" />}
              onClick={onOpen}
            >
              Legg til
            </Button>
          </Stack>
        </Box>
      ) : (
        <Box overflowX="auto">
          <Table>
            <Thead>
              <Tr>
                <Th onClick={() => handleSort("name")}>
                  <HStack spacing="3">
                    <Checkbox
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={handleTableHeaderCheckbox}
                    />
                    <HStack spacing="1">
                      <Text>Navn</Text>
                      {handleSortIcon("name")}
                    </HStack>
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("rating")}>
                  {" "}
                  <HStack spacing="1">
                    <Text>Din rating</Text>
                    {handleSortIcon("rating")}
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("dividend")}>
                  {" "}
                  <HStack spacing="1">
                    <Text>Utbytte</Text>
                    {handleSortIcon("dividend")}
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("sharePrice")}>
                  {" "}
                  <HStack spacing="1">
                    <Text>Aksjekurs</Text>
                    {handleSortIcon("sharePrice")}
                  </HStack>
                </Th>
                <Th onClick={() => handleSort("dividendYield")}>
                  {" "}
                  <HStack spacing="1">
                    <Text>Yield</Text>
                    {handleSortIcon("dividendYield")}
                  </HStack>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedWishlists.map((wishlist, index) => (
                <Tr key={index}>
                  <Td>
                    <HStack spacing="3">
                      <Checkbox
                        isChecked={checkedItems.has(wishlist.ticker)}
                        onChange={(e) =>
                          handleTableDataChange(e, wishlist.ticker)
                        }
                      />
                      <Avatar
                        name={wishlist.name}
                        src={`../logos/${wishlist.ticker}.png`}
                        boxSize="10"
                      />
                      <Box>
                        <Text fontWeight="medium">{wishlist.name}</Text>
                        <Text color="muted">{wishlist.ticker}</Text>
                      </Box>
                    </HStack>
                  </Td>
                  <Td>
                    <Text color="muted">
                      <Rating
                        defaultValue={wishlist.rating}
                        size="xl"
                        onClick={(rating) =>
                          handleRatingChange(wishlist.ticker, rating)
                        }
                      />
                    </Text>
                  </Td>
                  <Td>
                    <Text color="muted">{wishlist.dividend} kr</Text>
                  </Td>
                  <Td>
                    <Text color="muted">{wishlist.sharePrice} kr</Text>
                  </Td>
                  <Td>
                    <Text color="muted">{calculateYield(wishlist)} %</Text>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      {
        "Dersom det er aksjer i ønskelisten vises antall aksjer og eventuelle match for søk under tabellen"
      }
      {wishlistStocks.length !== 0 && (
        <Box px={{ base: "4", md: "6" }} pb="5">
          <HStack spacing="3" justify="space-between">
            <Text color="muted" fontSize="sm">
              {showTableCount()}
            </Text>
          </HStack>
        </Box>
      )}
    </Stack>
  );
};

export default Ønskeliste;
