import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import { Summary } from "./Summary";

const data = [
  {
    name: "Januar",
    Utbetaling: 300,
  },
  {
    name: "Februar",
    Utbetaling: 400,
  },
  {
    name: "Mars",
    Utbetaling: 500,
  },
  {
    name: "April",
    Utbetaling: 900,
  },
  {
    name: "Mai",
    Utbetaling: 1200,
  },
  {
    name: "Juni",
    Utbetaling: 500,
  },
  {
    name: "Juli",
    Utbetaling: 300,
  },
  {
    name: "August",
    Utbetaling: 200,
  },
  {
    name: "September",
    Utbetaling: 400,
  },
  {
    name: "Oktober",
    Utbetaling: 300,
  },
  {
    name: "November",
    Utbetaling: 200,
  },
  {
    name: "Desember",
    Utbetaling: 100,
  },
];

const formatYAxis = (tickItem: number) => {
  return tickItem + " kr";
};

const Kalender = () => {
  return (
    <Stack spacing={{ base: "6", lg: "6" }}>
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
      >
        <Stack spacing="1">
          <Heading size={{ base: "md", lg: "md" }} fontWeight="bold">
            Kalender
          </Heading>
        </Stack>
      </Stack>
      <Stack spacing={{ base: "5", lg: "6" }}>
        <SimpleGrid columns={{ base: 4, md: 4 }} gap={{ base: "5", md: "6" }}>
          <Summary label={"Fremtidige utbetalinger"} value={"14"} />
          <Summary label={"Største utbetaling"} value={"1 200 kr"} />
          <Summary label={"Estimert årlig utbetaling"} value={"5 670 kr"} />
          <Summary label={"Månedlig gjennomsnitt"} value={"320 kr"} />
        </SimpleGrid>
      </Stack>
      <Stack
        spacing="4"
        direction={{ base: "column", sm: "row" }}
        justify="space-between"
      ></Stack>
      <Text color="emphasized" fontWeight="medium">
        Årlig oversikt
      </Text>
      <Box
        bg="bg-surface"
        px={{ base: "4", md: "8" }}
        py="5"
        boxShadow="sm"
        borderRadius="lg"
      >
        <BarChart
          width={1100}
          height={400}
          data={data}
          margin={{ top: 20, right: 0, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="0 3" />
          <XAxis dataKey="name" tickLine={false} strokeOpacity={"15%"} />
          <YAxis
            tickFormatter={formatYAxis}
            tickLine={false}
            strokeOpacity={"15%"}
          />
          <Tooltip />
          <Bar dataKey="Utbetaling" fill="#63B3ED" />
        </BarChart>
      </Box>
      <Stack
        spacing="4"
        direction={{ base: "column", sm: "row" }}
        justify="space-between"
      ></Stack>
      <Text color="emphasized" fontWeight="medium">
        Januar
      </Text>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Selskap</Th>
              <Th>Ex dato</Th>
              <Th>Utbetalingsdato</Th>
              <Th>Pr aksje</Th>
              <Th>Valuta</Th>
              <Th>Utbetalt</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Equinor</Td>
              <Td>2. Januar</Td>
              <Td>25. Januar</Td>
              <Td>2 </Td>
              <Td>USD</Td>
              <Td>200 kr</Td>
            </Tr>
            <Tr>
              <Td>Yara</Td>
              <Td>13. Januar</Td>
              <Td>27. Januar</Td>
              <Td>10</Td>
              <Td>NOK</Td>
              <Td>100 kr</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <Stack
        spacing="4"
        direction={{ base: "column", sm: "row" }}
        justify="space-between"
      ></Stack>
      <Text color="emphasized" fontWeight="medium">
        Februar
      </Text>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Selskap</Th>
              <Th>Ex dato</Th>
              <Th>Utbetalingsdato</Th>
              <Th>Pr aksje</Th>
              <Th>Valuta</Th>
              <Th>Utbetalt</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Hydro</Td>
              <Td>12. Februar</Td>
              <Td>2. Februar</Td>
              <Td>5 </Td>
              <Td>NOK</Td>
              <Td>120 kr</Td>
            </Tr>
            <Tr>
              <Td>2020 Bulkers</Td>
              <Td>3. Februar</Td>
              <Td>23. Februar</Td>
              <Td>0.70</Td>
              <Td>USD</Td>
              <Td>310 kr</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default Kalender;
