import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  HStack,
} from "@chakra-ui/react";

import { Link, useLocation } from "react-router-dom";

export const MarketingNavbar = () => {
  const location = useLocation();

  return (
    <Box as="nav" bg="gray.800">
      <Container py={{ base: "4", lg: "5" }}>
        <HStack spacing="10" justify="space-between">
          <Flex justify="space-between" flex="1">
            <ButtonGroup variant="link" spacing="8">
              <Link to={"/"}>
                <Button
                  color="whiteAlpha.800"
                  _hover={{ color: "white" }}
                  aria-current={location.pathname === "/" ? "page" : undefined}
                  marginTop="10px"
                  fontWeight="bold"
                >
                  Utbyttebanken.no
                </Button>
              </Link>
              <Link to={"/priser"}>
                <Button
                  color="whiteAlpha.800"
                  _hover={{ color: "white" }}
                  aria-current={
                    location.pathname === "/priser" ? "page" : undefined
                  }
                  marginTop="10px"
                >
                  Priser
                </Button>
              </Link>
              <Link to={"/utbytteliste"}>
                <Button
                  color="whiteAlpha.800"
                  _hover={{ color: "white" }}
                  aria-current={
                    location.pathname === "/utbytteliste" ? "page" : undefined
                  }
                  marginTop="10px"
                >
                  Utbytteliste
                </Button>
              </Link>
            </ButtonGroup>
            <HStack spacing="3">
              <Link to={"/signup"}>
                <Button variant="primary">Kom i gang</Button>
              </Link>
              <Link to={"/login"}>
                <Button colorScheme="whiteAlpha">Logg inn</Button>
              </Link>
            </HStack>
          </Flex>
        </HStack>
      </Container>
    </Box>
  );
};

export default MarketingNavbar;
