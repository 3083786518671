import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { PricingCard } from "./PricingCard";

const Priser = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box as="section" bg="gray.800" py="24">
        <Box
          maxW={{ base: "xl", md: "5xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
        >
          <Flex
            direction="column"
            align={{ base: "flex-start", md: "center" }}
            maxW="2xl"
            mx="auto"
          >
            <Heading
              as="h1"
              size="2xl"
              letterSpacing="tight"
              fontWeight="extrabold"
              textAlign={{ base: "start", md: "center" }}
              color="white"
            >
              Priser
            </Heading>
            <Text
              mt="4"
              fontSize="xl"
              textAlign={{ base: "left", md: "center" }}
              color="whiteAlpha.800"
            >
              Sammenlign abonnement og funksjonalitet
            </Text>
          </Flex>
          <Flex
            direction={{ base: "column", lg: "row" }}
            maxW={{ base: "560px", lg: "unset" }}
            mx="auto"
            mt="10"
            rounded="xl"
          >
            <PricingCard
              flex="1"
              colorScheme="blue"
              name="Basic"
              description="Du får prøve enkelte av våre tjenester uten kostnad"
              price="Gratis"
              duration="Kostnadsfritt"
              extras=""
              features={[
                "Oversikt over dine aksjer i én portefølje",
                "Overvåk aksjer du ønsker å kjøpe senere",
                "Diversifisering på verdi og sektor",
                "Kalkulator for reinvestering av utbytter og ønsket inntekt",
              ]}
              buttonText="Kom i gang"
              onClick={() => navigate("/signup")}
            />
            <Box
              w={{ base: "unset", lg: "1px" }}
              minH="0"
              h={{ base: "1px", lg: "unset" }}
              bg="whiteAlpha.500"
            />
            <PricingCard
              flex="1"
              colorScheme="green"
              name="Premium"
              description="Du får tilgang til enda flere funksjoner"
              price="50 kr"
              duration="Per måned"
              extras=""
              features={[
                "Alt som inkluderes i Basic",
                "Varsling ved annonserte utbytter",
                "Oversikt over fremtidige utbetalinger",
                "Historikk om dine utbetalinger",
                "Opprett flere porteføljer",
                "Importer data i CSV-format",
              ]}
              buttonText="Oppgrader"
              onClick={() => navigate("/signup")}
            />
          </Flex>
        </Box>
      </Box>
      <Box as="section" bg="gray.800" py="40"></Box>
    </>
  );
};

export default Priser;
