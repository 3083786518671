import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import { Summary } from "./Summary";

const barChartData = [
  {
    name: "2018",
    Utbetalt: 2000,
  },
  {
    name: "2019",
    Utbetalt: 3000,
  },
  {
    name: "2020",
    Utbetalt: 4000,
  },
  {
    name: "2021",
    Utbetalt: 6000,
  },
  {
    name: "2022",
    Utbetalt: 8000,
  },
];

const yearlyPayoutData = [
  {
    month: "Januar",
    totalTransactions: 3,
    totalPayout: 240,
  },
  {
    month: "Februar",
    totalTransactions: 2,
    totalPayout: 190,
  },
  {
    month: "Mars",
    totalTransactions: 5,
    totalPayout: 670,
  },
  {
    month: "April",
    totalTransactions: 9,
    totalPayout: 943,
  },
  {
    month: "Mai",
    totalTransactions: 14,
    totalPayout: 1250,
  },
  {
    month: "Juni",
    totalTransactions: 6,
    totalPayout: 781,
  },
  {
    month: "Juli",
    totalTransactions: 0,
    totalPayout: 0,
  },
  {
    month: "August",
    totalTransactions: 2,
    totalPayout: 240,
  },
  {
    month: "September",
    totalTransactions: 4,
    totalPayout: 447,
  },
  {
    month: "Oktober",
    totalTransactions: 6,
    totalPayout: 802,
  },
  {
    month: "November",
    totalTransactions: 3,
    totalPayout: 320,
  },
  {
    month: "Desember",
    totalTransactions: 1,
    totalPayout: 56,
  },
];

const formatYAxis = (tickItem: number) => {
  return tickItem + " kr";
};

const Historikk = () => {
  return (
    <Stack spacing={{ base: "6", lg: "6" }}>
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
      >
        <Stack spacing="1">
          <Heading size={{ base: "md", lg: "md" }} fontWeight="bold">
            Historikk
          </Heading>
        </Stack>
      </Stack>
      <Stack spacing={{ base: "5", lg: "6" }}>
        <SimpleGrid columns={{ base: 3, md: 3 }} gap={{ base: "5", md: "6" }}>
          <Summary label={"Antall utbetalinger"} value={"235"} />
          <Summary label={"Største enkeltutbetaling"} value={"510 kr"} />
          <Summary label={"Totalt utbetalt"} value={"20 048 kr"} />
        </SimpleGrid>
      </Stack>
      <Stack
        spacing="4"
        direction={{ base: "column", sm: "row" }}
        justify="space-between"
      ></Stack>
      <Text color="emphasized" fontWeight="medium">
        Årlige utbetalinger
      </Text>
      <Box
        bg="bg-surface"
        px={{ base: "4", md: "8" }}
        py="5"
        boxShadow="sm"
        borderRadius="lg"
      >
        <BarChart
          width={1100}
          height={400}
          data={barChartData}
          margin={{ top: 20, right: 0, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="0 3" />
          <XAxis dataKey="name" tickLine={false} strokeOpacity={"15%"} />
          <YAxis
            tickFormatter={formatYAxis}
            tickLine={false}
            strokeOpacity={"15%"}
          />
          <Tooltip />
          <Bar dataKey="Utbetalt" fill="#63B3ED" />
        </BarChart>
      </Box>
      <Stack
        spacing="4"
        direction={{ base: "column", sm: "row" }}
        justify="space-between"
      ></Stack>
      <Text color="emphasized" fontWeight="medium">
        2022
      </Text>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Måned</Th>
              <Th>Antall utbetalinger</Th>
              <Th>Totalt utbetalt</Th>
            </Tr>
          </Thead>
          <Tbody>
            {yearlyPayoutData.map((data, id) => (
              <Tr>
                <Td>{data.month}</Td>
                <Td>{data.totalTransactions}</Td>
                <Td>{data.totalPayout} kr</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default Historikk;
