import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Summary } from "./Summary";
import { usePortfolio } from "./PortfolioContext";
import { useState, useEffect } from "react";
import { PortfolioStock } from "./Portfolio";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { FiHome } from "react-icons/fi";
import { Link } from "react-router-dom";

const Diversifisering = () => {
  const { portfolioStocks } = usePortfolio();
  const [largestStock, setLargestStock] = useState<PortfolioStock | null>(null);

  const totalPortfolioValue = portfolioStocks.reduce(
    (total, stock) => total + stock.stockValue,
    0
  );

  useEffect(() => {
    let maxStock = null;
    for (let i = 0; i < portfolioStocks.length; i++) {
      if (!maxStock || portfolioStocks[i].stockValue > maxStock.stockValue) {
        maxStock = portfolioStocks[i];
      }
    }
    setLargestStock(maxStock);
  }, [portfolioStocks]);

  const largestStockName =
    largestStock && largestStock.stockValue ? largestStock.name : "N/A";

  const filteredStocks = portfolioStocks.filter(
    (stock) =>
      stock.stockValue !== undefined &&
      stock.stockValue !== null &&
      stock.stockValue > 0
  );

  const data = filteredStocks.map((stock) => ({
    name: stock.name,
    ticker: stock.ticker,
    value: stock.stockValue,
    percentage: (stock.stockValue / totalPortfolioValue) * 100,
  }));

  const COLORS = [
    "#ea5545",
    "#f46a9b",
    "#ef9b20",
    "#edbf33",
    "#ede15b",
    "#bdcf32",
    "#87bc45",
    "#27aeef",
    "#b33dc6",
  ];

  return (
    <Stack spacing={{ base: "6", lg: "6" }}>
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
      >
        <Stack spacing="1">
          <Heading size={{ base: "md", lg: "md" }} fontWeight="bold">
            Diversifisering
          </Heading>
        </Stack>
      </Stack>

      {portfolioStocks.length > 0 ? (
        <>
          <Stack spacing={{ base: "5", lg: "6" }}>
            <SimpleGrid
              columns={{ base: 4, md: 4 }}
              gap={{ base: "5", md: "6" }}
            >
              <Summary
                label={"Antall aksjer"}
                value={portfolioStocks.length.toString()}
              />
              <Summary label={"Største beholdning"} value={largestStockName} />
            </SimpleGrid>
          </Stack>
          <Stack
            spacing="4"
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
          >
            <Stack spacing="1">
              <Text fontSize="lg" fontWeight="medium">
                Porteføljeverdi
              </Text>
            </Stack>
          </Stack>

          {filteredStocks.length > 0 ? (
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              gap={{ base: "8", md: "8" }}
              minChildWidth="580px"
            >
              <Box
                bg="bg-surface"
                px={{ base: "3", md: "10" }}
                py="5"
                boxShadow="sm"
                borderRadius="lg"
                width={{ base: "600px", md: "600px" }}
              >
                <PieChart width={500} height={250}>
                  <Pie
                    data={data}
                    dataKey="value"
                    nameKey="ticker"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    labelLine={false}
                    label={({
                      cx,
                      cy,
                      midAngle,
                      innerRadius,
                      outerRadius,
                      index,
                    }) => {
                      const RADIAN = Math.PI / 180;
                      const radius =
                        65 + innerRadius + (outerRadius - innerRadius) * 0.5;
                      const x = cx + radius * Math.cos(-midAngle * RADIAN);
                      const y = cy + radius * Math.sin(-midAngle * RADIAN);

                      return (
                        <text
                          x={x}
                          y={y}
                          fill={COLORS[index % COLORS.length]}
                          textAnchor={x > cx ? "start" : "end"}
                          dominantBaseline="central"
                        >
                          {`${data[index].ticker} `}
                        </text>
                      );
                    }}
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value, name, props) =>
                      `${props.payload.percentage.toFixed(0)}%`
                    }
                  />
                </PieChart>
              </Box>
            </SimpleGrid>
          ) : (
            <SimpleGrid
              columns={{ base: 2, md: 2 }}
              gap={{ base: "4", md: "4" }}
            >
              <Box
                bg="bg-surface"
                px={{ base: "4", md: "8" }}
                py="5"
                boxShadow="sm"
                borderRadius="lg"
              >
                <Stack
                  spacing="4"
                  direction={{ base: "column", sm: "row" }}
                  justify="space-between"
                >
                  <Stack spacing="1">
                    <Text fontSize="lg" fontWeight="medium">
                      Mangler data
                    </Text>
                    <Text color="muted" fontSize="sm">
                      Du må oppdatere verdiene i porteføljen for å se diagrammer
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            </SimpleGrid>
          )}
        </>
      ) : (
        <Box
          bg="bg-surface"
          px={{ base: "4", md: "8" }}
          py="5"
          boxShadow="sm"
          borderRadius="lg"
        >
          <Stack
            spacing="4"
            direction={{ base: "column", sm: "row" }}
            justify="space-between"
          >
            <Stack spacing="1">
              <Text fontSize="lg" fontWeight="medium">
                Porteføljen er tom
              </Text>
              <Text color="muted" fontSize="sm">
                Legg til aksjer i porteføljen for å se data
              </Text>
            </Stack>
            <Link to={"/"}>
              <Button
                variant="primary"
                leftIcon={<FiHome fontSize="1.25rem" />}
              >
                Gå til porteføljen
              </Button>
            </Link>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default Diversifisering;
