import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  VStack,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";
import { useState } from "react";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isCreateAccountToastOpen, setIsCreateAccountToastOpen] =
    useState(false);

  const toast = useToast();
  const navigate = useNavigate();

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);

      toast({
        title: "Bruker opprettet",
        description:
          "Brukeren din har nå blitt opprettet, du kan logge inn for å bruke tjenesten",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      if (!isCreateAccountToastOpen) {
        setIsCreateAccountToastOpen(true);
        toast({
          title: "Brukeren ble ikke opprettet",
          description:
            "Det oppsto en feil ved opprettelse av brukeren din, vennligst prøv igjen",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {
            setIsCreateAccountToastOpen(false);
          },
        });
      }
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <>
      <Box as="section" bg="gray.800" py="8">
        <Container maxW="md" py={{ base: "12", md: "24" }}>
          <Stack spacing="8">
            <Stack spacing="6" align="center">
              <Stack spacing="3" textAlign="center">
                <Heading color="white" size={{ base: "xs", md: "sm" }}>
                  Opprett en bruker
                </Heading>
                <Text color="whiteAlpha.800">
                  Du trenger en konto for å bruke tjenesten
                </Text>
              </Stack>
            </Stack>
            <form onSubmit={handleSignUp} noValidate>
              <Stack spacing="6">
                <Stack spacing="5">
                  <FormControl isRequired>
                    <FormLabel htmlFor="email" color="whiteAlpha.700">
                      Epost
                    </FormLabel>
                    <Input
                      id="email"
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel htmlFor="password" color="whiteAlpha.700">
                      Passord
                    </FormLabel>
                    <Input
                      id="password"
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </FormControl>
                </Stack>
                <HStack justify="space-between"></HStack>
                <Stack spacing="4">
                  <Button variant="primary" type="submit">
                    Opprett konto
                  </Button>
                </Stack>
              </Stack>
            </form>
            <VStack justify="center" spacing="4">
              <Link to={"/login"}>
                <Text fontSize="md" color="whiteAlpha.800">
                  Har du allerede en bruker?
                </Text>
              </Link>
            </VStack>
          </Stack>
        </Container>
      </Box>
      <Box as="section" bg="gray.800" py="40"></Box>
      <Box as="section" bg="gray.800" py="40"></Box>
    </>
  );
};

export default SignUp;
