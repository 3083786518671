import { Box, CloseButton, Container, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";

const DevelopmentBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  return (
    <>
      {isBannerVisible && (
        <Box as="section">
          <Box bg="bg-accent" color="on-accent" position="relative">
            <Container py={{ base: "4", md: "3.5" }}>
              <Stack
                direction={{ base: "column", md: "row" }}
                justify="center"
                spacing={{ base: "0.5", md: "1.5" }}
                pe={{ base: "4", sm: "0" }}
              >
                <Text color="on-accent-muted">
                  Vi utvikler tjenesten kontinuerlig, og du kan derfor oppleve
                  små feil i en begrenset periode.
                </Text>
              </Stack>
              <CloseButton
                position="absolute"
                right="2"
                top="2.5"
                onClick={handleCloseBanner}
              />
            </Container>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DevelopmentBanner;
