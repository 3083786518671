import { Heading, Stack } from "@chakra-ui/react";

import AccountSettings from "./settings/AccountSettings";
import NotificationSettings from "./settings/NotificationSettings";
// import ImportDataSettings from "./settings/ImportDataSettings";
// import ReportSettings from "./settings/ReportSettings";

const Innstillinger = () => {
  return (
    <Stack spacing={{ base: "6", lg: "6" }}>
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
      >
        <Stack spacing="1">
          <Heading size={{ base: "md", lg: "md" }} fontWeight="bold">
            Innstillinger
          </Heading>
        </Stack>
      </Stack>
      <AccountSettings />
      <NotificationSettings />
      {/* <ImportDataSettings /> */}
      {/* <ReportSettings /> */}
    </Stack>
  );
};

export default Innstillinger;
