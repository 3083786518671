export const stockOptions = [
  { value: "5PG", label: "5TH PLANET GAMES" },
  { value: "AASB", label: "AASEN SPAREBANK" },
  { value: "ABG", label: "ABG SUNDAL COLLIER" },
  { value: "ABL", label: "ABL GROUP" },
  { value: "ADE", label: "ADEVINTA" },
  { value: "ADS", label: "ADS MARITIME HOLD" },
  { value: "AEGA", label: "AEGA" },
  { value: "AFG", label: "AF GRUPPEN" },
  { value: "AGLX", label: "AGILYX" },
  { value: "AIRX", label: "AIRTHINGS" },
  { value: "AKAST", label: "AKASTOR" },
  { value: "AKER", label: "AKER" },
  { value: "AKBM", label: "AKER BIOMARINE" },
  { value: "AKRBP", label: "AKER BP" },
  { value: "ACC", label: "AKER CARBON CAPTUR" },
  { value: "AKH", label: "AKER HORIZONS" },
  { value: "AKSO", label: "AKER SOLUTIONS" },
  { value: "AKOBO", label: "AKOBO MINERALS" },
  { value: "AKVA", label: "AKVA GROUP" },
  { value: "ALT", label: "ALTERNUS ENERGY GR" },
  { value: "AMSC", label: "AMSC" },
  { value: "ANDF", label: "ANDFJORD SALMON" },
  { value: "ABTEC", label: "AQUA BIO TECHNO" },
  { value: "AQUIL", label: "AQUILA HOLDINGS" },
  { value: "ARCH", label: "ARCHER" },
  { value: "ABS", label: "ARCTIC BIOSCIENCE" },
  { value: "AFISH", label: "ARCTIC FISH HOLDIN" },
  { value: "AZT", label: "ARCTICZYMES TECHNO" },
  { value: "AFK", label: "ARENDALS FOSSEKOMP" },
  { value: "ARGEO", label: "ARGEO" },
  { value: "ARR", label: "ARRIBATEC GROUP" },
  { value: "ASTK", label: "ASETEK" },
  { value: "ASTRO", label: "ASTROCAST" },
  { value: "ATEA", label: "ATEA" },
  { value: "ASA", label: "ATLANTIC SAPPHIRE" },
  { value: "AURA", label: "AURORA EIENDOM" },
  { value: "AURG", label: "AURSKOG SPAREBANK" },
  { value: "AUSS", label: "AUSTEVOLL SEAFOOD" },
  { value: "AUTO", label: "AUTOSTORE HOLDINGS" },
  { value: "AGAS", label: "AVANCE GAS HOLDING" },
  { value: "AWDR", label: "AWILCO DRILLING" },
  { value: "ALNG", label: "AWILCO LNG" },
  { value: "ACR", label: "AXACTOR" },
  { value: "AYFIE", label: "AYFIE GROUP" },
  { value: "B2I", label: "B2 Impact" },
  { value: "BAKKA", label: "BAKKAFROST" },
  { value: "BALT", label: "BALTIC SEA PROP" },
  { value: "BARRA", label: "BARRAMUNDI GROUP" },
  { value: "BBERG", label: "BEERENBERG" },
  { value: "BELCO", label: "BELSHIPS" },
  { value: "BMK", label: "BENCHMARK HOLDINGS" },
  { value: "BCS", label: "BERGEN CARBON SOL" },
  { value: "BGBIO", label: "BERGENBIO" },
  { value: "BGBIS", label: "BerGenBio TR" },
  { value: "BEWI", label: "BEWI" },
  { value: "BIEN", label: "BIEN SPAREBANK" },
  { value: "BFISH", label: "BIOFISH HOLDING" },
  { value: "BSP", label: "BLACK SEA PROPERTY" },
  { value: "BNOR", label: "BLUENORD" },
  { value: "BONHR", label: "BONHEUR" },
  { value: "BOR", label: "BORGESTAD" },
  { value: "BORR", label: "BORR DRILLING" },
  { value: "BRG", label: "BORREGAARD" },
  { value: "BOUV", label: "BOUVET" },
  { value: "BWE", label: "BW ENERGY LIMITED" },
  { value: "BWEK", label: "BW Epic Kosan" },
  { value: "BWIDL", label: "BW IDEOL" },
  { value: "BWLPG", label: "BW LPG" },
  { value: "BWO", label: "BW OFFSHORE LTD" },
  { value: "BMA", label: "BYGGMA" },
  { value: "CADLR", label: "CADELER" },
  { value: "CAMBI", label: "CAMBI" },
  { value: "CAPSL", label: "CAPSOL TECHNOLOGI" },
  { value: "CARA", label: "CARASENT" },
  { value: "CIRCA", label: "CIRCA GROUP" },
  { value: "CRNA", label: "CIRCIO HOLDING" },
  { value: "CSS", label: "CLEAN SEAS SEAFOOD" },
  { value: "CLOUD", label: "CLOUDBERRY CLEAN" },
  { value: "CODE", label: "CODELAB CAPITAL" },
  { value: "CONTX", label: "CONTEXTVISION" },
  { value: "CLCO", label: "COOL COMPANY" },
  { value: "CRAYN", label: "CRAYON GROUP HOLD" },
  { value: "CYVIZ", label: "CYVIZ" },
  { value: "DVD", label: "DEEP VALUE DRILLER" },
  { value: "DSRT", label: "DESERT CONTROL" },
  { value: "DNB", label: "DNB BANK" },
  { value: "DNO", label: "DNO" },
  { value: "DOFG", label: "DOF GROUP" },
  { value: "DDRIL", label: "DOLPHIN DRILLING" },
  { value: "EAM", label: "EAM SOLAR" },
  { value: "ECIT", label: "ECIT AS B-AKSJER" },
  { value: "EWIND", label: "EDDA WIND" },
  { value: "EIOF", label: "EIDESVIK OFFSHORE" },
  { value: "EMGS", label: "ELECTROMAGNET GEO" },
  { value: "ELIMP", label: "ELEKTROIMPORTØREN" },
  { value: "ELK", label: "ELKEM" },
  { value: "ELABS", label: "ELLIPTIC LABORATOR" },
  { value: "ELMRA", label: "ELMERA GROUP" },
  { value: "ELO", label: "ELOPAK" },
  { value: "ENDUR", label: "ENDÚR" },
  { value: "ENERG", label: "ENERGEIA" },
  { value: "ENSU", label: "ENSURGE MICROPOWER" },
  { value: "ENTRA", label: "ENTRA" },
  { value: "ENVIP", label: "ENVIPCO HOLDING" },
  { value: "EQNR", label: "EQUINOR" },
  { value: "EQVA", label: "EQVA" },
  { value: "EPR", label: "EUROPRIS" },
  { value: "EFUEL", label: "EVERFUEL" },
  { value: "EXTX", label: "EXACT THERAPEUTICS" },
  { value: "FLNG", label: "FLEX LNG" },
  { value: "FRO", label: "FRONTLINE" },
  { value: "GIG", label: "GAMING INNOVATION" },
  { value: "RISH", label: "GC RIEBER SHIPPING" },
  { value: "GENT", label: "GENTIAN DIAGNOSTIC" },
  { value: "GIGA", label: "GIGANTE SALMON" },
  { value: "GJF", label: "GJENSIDIGE FORSIKR" },
  { value: "GEOS", label: "GOLDEN ENERGY OFF" },
  { value: "GOGL", label: "GOLDEN OCEAN GROUP" },
  { value: "GOD", label: "GOODTECH" },
  { value: "GCC", label: "GRAM CAR CARRIERS" },
  { value: "GEM", label: "GREEN MINERALS" },
  { value: "GSF", label: "GRIEG SEAFOOD" },
  { value: "GRONG", label: "GRONG SPAREBANK" },
  { value: "GYL", label: "GYLDENDAL" },
  { value: "HAFNI", label: "HAFNIA LIMITED" },
  { value: "HAV", label: "HAV GROUP" },
  { value: "HKY", label: "HAVILA KYSTRUTEN" },
  { value: "HAVI", label: "HAVILA SHIPPING" },
  { value: "HEX", label: "HEXAGON COMPOSITES" },
  { value: "HPUR", label: "HEXAGON PURUS" },
  { value: "HSHP", label: "HIMALAYA SHIPPING" },
  { value: "HBC", label: "HOFSETH BIOCARE" },
  { value: "HRGI", label: "HORISONT ENERGI" },
  { value: "HUDL", label: "HUDDLESTOCK FINTEC" },
  { value: "HDLY", label: "HUDDLY" },
  { value: "HUNT", label: "HUNTER GROUP" },
  { value: "HYPRO", label: "HYDROGENPRO" },
  { value: "HYN", label: "HYNION" },
  { value: "HYON", label: "HYON" },
  { value: "HAUTO", label: "HÖEGH AUTOLINERS" },
  { value: "HSPG", label: "HØLAND OG SETSKOG" },
  { value: "IFISH", label: "ICE FISH FARM" },
  { value: "ISLAX", label: "ICELANDIC SALMON" },
  { value: "IDEX", label: "IDEX BIOMETRICS" },
  { value: "INDCT", label: "INDUCT" },
  { value: "INIFY", label: "INIFY LABORATORIES" },
  { value: "ININ", label: "ININ GROUP" },
  { value: "INSTA", label: "INSTABANK" },
  { value: "IWS", label: "INTEGRATED WIND SO" },
  { value: "IOX", label: "INTEROIL EXPL PROD" },
  { value: "ITERA", label: "ITERA" },
  { value: "JIN", label: "JINHUI SHIPP TRANS" },
  { value: "JAREN", label: "JÆREN SPAREBANK" },
  { value: "KAHOT", label: "KAHOOT!" },
  { value: "KID", label: "KID" },
  { value: "KIT", label: "KITRON" },
  { value: "KCC", label: "KLAVENESS COMBINAT" },
  { value: "KMCP", label: "KMC PROPERTIES" },
  { value: "KOMPL", label: "KOMPLETT" },
  { value: "KOA", label: "KONGSBERG AUTOMOT" },
  { value: "KOG", label: "KONGSBERG GRUPPEN" },
  { value: "KRAB", label: "KRAFT BANK" },
  { value: "KYOTO", label: "KYOTO GROUP" },
  { value: "LEA", label: "LEA BANK" },
  { value: "LSG", label: "LERØY SEAFOOD GP" },
  { value: "LIFE", label: "LIFECARE" },
  { value: "LINK", label: "LINK MOBILITY GRP" },
  { value: "LOKO", label: "LOKOTECH GROUP" },
  { value: "LUMI", label: "LUMI GRUPPEN" },
  { value: "LYTIX", label: "LYTIX BIOPHARMA" },
  { value: "MVW", label: "M VEST WATER" },
  { value: "MGN", label: "MAGNORA" },
  { value: "MVE", label: "MATVAREEXPRESSEN" },
  { value: "MEDI", label: "MEDISTIM" },
  { value: "MELG", label: "MELHUS SPAREBANK" },
  { value: "MNTR", label: "MINTRA HOLDING" },
  { value: "MOBA", label: "MORROW BANK" },
  { value: "MOWI", label: "MOWI" },
  { value: "MPCC", label: "MPC CONTAINER SHIP" },
  { value: "MPCES", label: "MPC ENERGY SOLUTIO" },
  { value: "MULTI", label: "MULTICONSULT" },
  { value: "MAS", label: "MÅSØVAL" },
  { value: "NAPA", label: "NAPATECH" },
  { value: "NAVA", label: "NAVAMEDIC" },
  { value: "NKR", label: "NEKKAR" },
  { value: "NEL", label: "NEL" },
  { value: "NEXT", label: "NEXT BIOMETRICS GP" },
  { value: "NISB", label: "NIDAROS SPAREBANK" },
  { value: "NORAM", label: "NORAM DRILLING" },
  { value: "NORBT", label: "NORBIT" },
  { value: "NCOD", label: "NORCOD" },
  { value: "NORCO", label: "NORCONSULT" },
  { value: "NORDH", label: "NORDHEALTH A-AKSJE" },
  { value: "NOAP", label: "NORDIC AQUA PART" },
  { value: "NOHAL", label: "NORDIC HALIBUT" },
  { value: "NOM", label: "NORDIC MINING" },
  { value: "NOD", label: "NORDIC SEMICONDUC" },
  { value: "NTG", label: "NORDIC TECHNOLOGY" },
  { value: "NUMND", label: "NORDIC UNMANNED" },
  { value: "NORSE", label: "NORSE ATLANTIC" },
  { value: "NHY", label: "NORSK HYDRO" },
  { value: "NSOL", label: "NORSK SOLAR" },
  { value: "NTI", label: "NORSK TITANIUM" },
  { value: "NSKOG", label: "NORSKE SKOG" },
  { value: "NORTH", label: "NORTH ENERGY" },
  { value: "NODL", label: "NORTHERN DRILLING" },
  { value: "NOL", label: "NORTHERN OCEAN LTD" },
  { value: "NAS", label: "NORWEGIAN AIR SHUT" },
  { value: "NBX", label: "NORWEGIAN BLOCK EX" },
  { value: "NRC", label: "NRC GROUP" },
  { value: "NYKD", label: "NYKODE THERAPEUTIC" },
  { value: "OBSRV", label: "OBSERVE MEDICAL" },
  { value: "OBSRT", label: "OBSERVE MEDICAL TR" },
  { value: "OCEAN", label: "OCEAN GEOLOOP" },
  { value: "OSUN", label: "OCEAN SUN" },
  { value: "OTS", label: "OCEANTEAM" },
  { value: "ODL", label: "ODFJELL DRILLING" },
  { value: "ODF", label: "ODFJELL SER. A" },
  { value: "ODFB", label: "ODFJELL SER. B" },
  { value: "OTL", label: "ODFJELL TECHNOLOGY" },
  { value: "OKEA", label: "OKEA" },
  { value: "OET", label: "OKEANIS ECO TANKER" },
  { value: "OLT", label: "OLAV THON EIENDOMS" },
  { value: "OMDA", label: "OMDA" },
  { value: "ORK", label: "ORKLA" },
  { value: "OTEC", label: "OTELLO CORPORATION" },
  { value: "OTOVO", label: "OTOVO" },
  { value: "PEN", label: "PANORO ENERGY" },
  { value: "PARB", label: "PARETO BANK" },
  { value: "PCIB", label: "PCI BIOTECH HOLD" },
  { value: "PSE", label: "PETROLIA" },
  { value: "PNOR", label: "PETRONOR E&P" },
  { value: "PEXIP", label: "PEXIP HOLDING" },
  { value: "PGS", label: "PGS" },
  { value: "PHLY", label: "PHILLY SHIPYARD" },
  { value: "PHO", label: "PHOTOCURE" },
  { value: "PPG", label: "PIONEER PROPERTY" },
  { value: "POL", label: "POLARIS MEDIA" },
  { value: "PLT", label: "POLIGHT" },
  { value: "PRS", label: "PROSAFE" },
  { value: "PROT", label: "PROTECTOR FORSIKRG" },
  { value: "PROXI", label: "PROXIMAR SEAFOOD" },
  { value: "PRYME", label: "PRYME" },
  { value: "PYRUM", label: "PYRUM INNOVATIONS" },
  { value: "QFR", label: "Q-FREE" },
  { value: "QFUEL", label: "QUANTAFUEL" },
  { value: "QUEST", label: "QUESTBACK GROUP" },
  { value: "QEC", label: "QUESTERRE ENERGY" },
  { value: "RANA", label: "RANA GRUBER" },
  { value: "REACH", label: "REACH SUBSEA" },
  { value: "RECSI", label: "REC SILICON" },
  { value: "RCR", label: "RECREATE" },
  { value: "REFL", label: "REFUELS" },
  { value: "RIVER", label: "RIVER TECH" },
  { value: "ROMER", label: "ROMERIKE SPAREBK" },
  { value: "ROM", label: "ROMREAL" },
  { value: "ROMSB", label: "ROMSDAL SPAREBANK" },
  { value: "SDSD", label: "S.D. STANDARD ETC" },
  { value: "SAGA", label: "SAGA PURE" },
  { value: "SALM", label: "SALMAR" },
  { value: "SALME", label: "SALMON EVOLUTION" },
  { value: "SACAM", label: "SALMONES CAMANCHAC" },
  { value: "SADG", label: "SANDNES SPAREBANK" },
  { value: "SASNO", label: "SAS AB" },
  { value: "SATS", label: "SATS" },
  { value: "SCANA", label: "SCANA" },
  { value: "SCATC", label: "SCATEC" },
  { value: "SCHA", label: "SCHIBSTED SER. A" },
  { value: "SCHB", label: "SCHIBSTED SER. B" },
  { value: "SBX", label: "SEABIRD EXPLORAT" },
  { value: "SEAPT", label: "SEACREST PETROLEO" },
  { value: "SDRL", label: "SEADRILL" },
  { value: "SSG", label: "SELF STORAGE GROUP" },
  { value: "SBO", label: "SELVAAG BOLIG" },
  { value: "SHLF", label: "SHELF DRILLING" },
  { value: "SDNS", label: "SHELF DRILLING NO" },
  { value: "SIOFF", label: "SIEM OFFSHORE" },
  { value: "SIKRI", label: "SIKRI GROUP" },
  { value: "SKAND", label: "SKANDIA GREENPOWER" },
  { value: "SKUE", label: "SKUE SPAREBANK" },
  { value: "SMCRT", label: "SMARTCRAFT" },
  { value: "SMOP", label: "SMARTOPTICS GROUP" },
  { value: "SOFTX", label: "SOFTOX SOLUTIONS" },
  { value: "SOGN", label: "SOGN SPAREBANK" },
  { value: "SOFF", label: "SOLSTAD OFFSHORE" },
  { value: "SB68", label: "SPARBNK 68 GR NORD" },
  { value: "MING", label: "SPAREBANK 1 SMN" },
  { value: "SRBNK", label: "SPAREBANK 1 SR-BK" },
  { value: "SOON", label: "SPAREBANK 1 SØRØ" },
  { value: "MORG", label: "SPAREBANKEN MØRE" },
  { value: "SOR", label: "SPAREBANKEN SØR" },
  { value: "SVEG", label: "SPAREBANKEN VEST" },
  { value: "SPOG", label: "SPAREBANKEN ØST" },
  { value: "SNOR", label: "SPBK 1 NORDMØRE" },
  { value: "SPOL", label: "SPBK 1 ØSTLANDET" },
  { value: "HELG", label: "SPBK1 HELGELAND" },
  { value: "NONG", label: "SPBK1 NORD-NORGE" },
  { value: "RING", label: "SPBK1 RINGERIKE" },
  { value: "SOAG", label: "SPBK1 ØSTFOLD AKE" },
  { value: "SPOT", label: "SPOTLIO" },
  { value: "STST", label: "STAINLESS TANKERS" },
  { value: "STSU", label: "STANDARD SUPPLY" },
  { value: "STATT", label: "STATT TORSK" },
  { value: "SNI", label: "STOLT-NIELSEN" },
  { value: "STB", label: "STOREBRAND" },
  { value: "STRO", label: "STRONGPOINT" },
  { value: "SUBC", label: "SUBSEA 7" },
  { value: "SUNSB", label: "SUNNDAL SPAREBANK" },
  { value: "TECH", label: "TECHSTEP" },
  { value: "TECO", label: "TECO 2030" },
  { value: "TEKNA", label: "TEKNA HOLDING" },
  { value: "TEL", label: "TELENOR" },
  { value: "TGS", label: "TGS" },
  { value: "KING", label: "THE KINGFISH COMP" },
  { value: "TRMED", label: "THOR MEDICAL" },
  { value: "TIETO", label: "TIETOEVRY" },
  { value: "TOM", label: "TOMRA SYSTEMS" },
  { value: "TOTG", label: "TOTENS SPAREBANK" },
  { value: "TRE", label: "TREASURE" },
  { value: "TYSB", label: "TYSNES SPAREBANK" },
  { value: "ULTI", label: "ULTIMOVACS" },
  { value: "UNIV", label: "UNIVID" },
  { value: "VEI", label: "VEIDEKKE" },
  { value: "VISTN", label: "VISTIN PHARMA" },
  { value: "VOLUE", label: "VOLUE" },
  { value: "VVL", label: "VOSS VEKSEL OGLAND" },
  { value: "VOW", label: "VOW" },
  { value: "VGM", label: "VOW GREEN METALS" },
  { value: "VAR", label: "VÅR ENERGI" },
  { value: "WAWI", label: "WALLENIUS WILHELMS" },
  { value: "WSTEP", label: "WEBSTEP" },
  { value: "WEST", label: "WESTERN BULK CHART" },
  { value: "WWI", label: "WILH. WILHELMSEN A" },
  { value: "WWIB", label: "WILH. WILHELMSEN B" },
  { value: "WPU", label: "WPU - WASTE PLASTI" },
  { value: "XPLRA", label: "XPLORA TECHNOLOGIE" },
  { value: "XXL", label: "XXL" },
  { value: "YAR", label: "YARA INTERNATIONAL" },
  { value: "ZAL", label: "ZALARIS" },
  { value: "ZAP", label: "ZAPTEC" },
  { value: "ZENA", label: "ZENITH ENERGY" },
  { value: "ZWIPE", label: "ZWIPE" },
  { value: "ZWIPT", label: "ZWIPE TR" },
];
