import {
  Box,
  Button,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormLabel,
  FormControl,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { auth } from "../firebase";
import { updateEmail } from "firebase/auth";

const AccountSettings = () => {
  const [email, setEmail] = useState<string>("");
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [isUpgradingSubscription, setIsUpgradingSubscription] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const setToast = useToast();

  useEffect(() => {
    const user = auth.currentUser;
    if (user && user.email) {
      setEmail(user.email);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      const user = auth.currentUser;
      if (user && user.email) {
        setEmail(user.email);
      }
    }
  }, [isOpen]);

  const handleUpdateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSave = () => {
    const user = auth.currentUser;
    if (user) {
      updateEmail(user, email)
        .then(() => {
          onClose();
          setToast({
            title: "Eposten ble oppdatert",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((error) => {
          setToast({
            title: "Det skjedde en feil, vennligst prøv igjen",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  // Update profile in modal
  const handleUpdateProfile = () => {
    onOpen();
    setIsUpdatingProfile(true);
  };

  // Update subscription in modal
  const handleUpgradeSubscription = () => {
    onOpen();
    setIsUpgradingSubscription(true);
  };

  // Close modal when cancelling adding or editing
  const handleModalClose = () => {
    onClose();
    setIsUpdatingProfile(false);
    setIsUpgradingSubscription(false);
  };

  return (
    <>
      <Box
        bg="bg-surface"
        boxShadow="sm"
        borderRadius="lg"
        p={{ base: "4", md: "6" }}
      >
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack spacing="1">
            <Text fontSize="xl" fontWeight="medium">
              Min konto
            </Text>
          </Stack>
          <Stack justify="space-between" direction="row" spacing="4">
            <Stack spacing="0.5" fontSize="sm">
              <Text color="emphasized" fontWeight="medium">
                Profil
              </Text>
              <Text color="muted">Oppdater din personlige informasjon</Text>
            </Stack>
            <Button colorScheme="blue" onClick={handleUpdateProfile}>
              Oppdater
            </Button>
          </Stack>
          <Stack justify="space-between" direction="row" spacing="4">
            <Stack spacing="0.5" fontSize="sm">
              <Text color="emphasized" fontWeight="medium">
                Abonnement
              </Text>
              <Text color="muted">
                Opprett et abonnement for å få tilgang til flere features
              </Text>
            </Stack>
            <Button colorScheme="blue" onClick={handleUpgradeSubscription}>
              Få tilgang
            </Button>
          </Stack>
        </Stack>
      </Box>

      {isUpdatingProfile && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          closeOnOverlayClick={false}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Oppdater profil</ModalHeader>
            <ModalCloseButton onClick={handleModalClose} />
            <ModalBody pb={6}>
              <FormControl mt={4}>
                <FormLabel>Epost</FormLabel>
                <Input
                  placeholder="ola.nordmann@gmail.com"
                  type="email"
                  value={email}
                  onChange={handleUpdateEmail}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                colorScheme="blue"
                mr={3}
                onClick={handleSave}
              >
                Lagre
              </Button>
              <Button onClick={handleModalClose}>Avbryt</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {isUpgradingSubscription && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          closeOnOverlayClick={false}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Kommer snart</ModalHeader>
            <ModalCloseButton onClick={handleModalClose} />
            <ModalBody pb={6}>
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "5", md: "6" }}
                justify="space-between"
              >
                <Stack spacing="5">
                  <Text fontSize="sm" color="muted">
                    Vi jobber kontinuerlig med å videreutvikle tjenesten.
                  </Text>
                  <Text fontSize="sm" color="muted">
                    Du vil snart få tilgang til live data, varslinger, historikk
                    og mye mer for bare 50 kr pr mnd.
                  </Text>
                </Stack>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" aria-disabled colorScheme="blue" mr={3}>
                Oppgrader
              </Button>
              <Button onClick={handleModalClose}>Avbryt</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AccountSettings;
