import { createContext, useContext, useState } from "react";
import { PortfolioStock } from "./Portfolio";

type PortfolioContextType = {
  portfolioStocks: PortfolioStock[];
  setPortfolioStocks: (stocks: PortfolioStock[]) => void;
};

export const PortfolioContext = createContext<PortfolioContextType>({
  portfolioStocks: [],
  setPortfolioStocks: () => {},
});

export const usePortfolio = () => useContext(PortfolioContext);

export const PortfolioProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [portfolioStocks, setPortfolioStocks] = useState<PortfolioStock[]>([]);

  const value = {
    portfolioStocks,
    setPortfolioStocks,
  };

  return (
    <PortfolioContext.Provider value={value}>
      {children}
    </PortfolioContext.Provider>
  );
};
