import { HStack, Icon, StackProps, useColorModeValue } from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";

interface Props {
  defaultValue?: number;
  max?: number;
  size?: "sm" | "md" | "lg" | "xl";
  rootProps?: StackProps;
  onClick?: (value: number) => void;
}

export const Rating = (props: Props) => {
  const { defaultValue = 0, max = 5, size = "md", rootProps, onClick } = props;
  const color = useColorModeValue("gray.200", "gray.600");
  const activeColor = useColorModeValue("blue.500", "blue.200");
  return (
    <HStack spacing="0.5" {...rootProps}>
      {Array.from({ length: max })
        .map((_, index) => index + 1)
        .map((index) => (
          <Icon
            key={index}
            as={FaStar}
            fontSize={size}
            color={index <= defaultValue ? activeColor : color}
            onClick={() => onClick && onClick(index)}
          />
        ))}
    </HStack>
  );
};
