import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Stack,
  Text,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Summary } from "./Summary";

const Kalkulator = () => {
  // INCOME STATE
  const [incomeGoal, setIncomeGoal] = useState("");
  const [incomeDividendYield, setIncomeDividendYield] = useState("");
  const [incomeResult, setIncomeResult] = useState("");

  // REINVESTMENT STATE
  const [reinvestmentDividendYield, setReinvestmentDividendYield] =
    useState("");
  const [years, setYears] = useState("");
  const [initialInvestment, setInitialInvestment] = useState("");
  const [reinvestmentResult, setReinvestmentResult] = useState("");
  const [reinvestedDividendAmounts, setReinvestedDividendAmounts] = useState<
    { year: number; amount: number }[]
  >([]);

  const handleIncomeSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newIncomeGoal = Number(incomeGoal);
    const newIncomeDividendYield = Number(incomeDividendYield) / 100;

    const newIncomeResult = newIncomeGoal / newIncomeDividendYield;
    setIncomeResult(newIncomeResult.toString());
  };

  const handleReinvestmentSubmit = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const initialInvestmentAmount = Number(initialInvestment);
    const dividendYieldPercent = Number(reinvestmentDividendYield);
    const yearsNumber = Number(years);

    const reinvestedDividendAmounts = [];
    let currentAmount = initialInvestmentAmount;
    for (let i = 1; i <= yearsNumber; i++) {
      const reinvestedDividendAmount =
        currentAmount * (dividendYieldPercent / 100);
      currentAmount += reinvestedDividendAmount;
      reinvestedDividendAmounts.push({
        year: i,
        amount: reinvestedDividendAmount,
      });
    }

    const totalAmount = currentAmount;
    setReinvestmentResult(totalAmount.toFixed(2));
    setReinvestedDividendAmounts(reinvestedDividendAmounts);
  };

  // Format a number with Norwegian currency formatting
  const formatNumber = (value: string | number): string => {
    const numberValue = typeof value === "string" ? parseFloat(value) : value;
    return numberValue.toLocaleString("no-NO", {
      style: "currency",
      currency: "NOK",
      minimumFractionDigits: 0,
    });
  };

  return (
    <Stack spacing={{ base: "6", lg: "6" }}>
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
      >
        <Stack spacing="1">
          <Heading size={{ base: "md", lg: "md" }} fontWeight="bold">
            Kalkulator
          </Heading>
        </Stack>
      </Stack>

      <Text color="emphasized" fontWeight="medium">
        Målsetning inntekt
      </Text>
      <Stack spacing={{ base: "5", lg: "6" }}>
        <SimpleGrid columns={{ base: 2, md: 2 }} gap={{ base: "5", md: "6" }}>
          <Box p="6" bg="white" rounded="md" shadow="base">
            <form onSubmit={handleIncomeSubmit}>
              <Stack spacing="4">
                <FormControl>
                  <FormLabel>Ønsket inntekt</FormLabel>
                  <Input
                    type="number"
                    placeholder="100 000"
                    value={incomeGoal}
                    onChange={(e) => setIncomeGoal(e.target.value)}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Årlig yield</FormLabel>
                  <Input
                    type="number"
                    placeholder="4%"
                    value={incomeDividendYield}
                    onChange={(e) => setIncomeDividendYield(e.target.value)}
                    required
                  />
                </FormControl>
              </Stack>
              <Flex pt="5" pl="1">
                <Button type="submit" variant="primary">
                  Regn ut
                </Button>
              </Flex>
            </form>
          </Box>
        </SimpleGrid>
      </Stack>
      {incomeResult && (
        <>
          <Stack spacing={{ base: "5", lg: "6" }}>
            <SimpleGrid
              columns={{ base: 2, md: 2 }}
              gap={{ base: "5", md: "6" }}
            >
              <Summary
                label={`Beløp du må investere`}
                value={formatNumber(incomeResult) + " kr"}
              />
            </SimpleGrid>
          </Stack>
        </>
      )}
      <Stack
        spacing="4"
        direction={{ base: "column", sm: "row" }}
        justify="space-between"
      ></Stack>
      <Text color="emphasized" fontWeight="medium">
        Reinvestert utbytte
      </Text>
      <Stack spacing={{ base: "5", lg: "6" }}>
        <SimpleGrid columns={{ base: 2, md: 2 }} gap={{ base: "5", md: "6" }}>
          <Box p="6" bg="white" rounded="md" shadow="base">
            <form onSubmit={handleReinvestmentSubmit}>
              <Stack spacing="4">
                <FormControl>
                  <FormLabel>Investert beløp</FormLabel>
                  <Input
                    type="number"
                    placeholder="100 000"
                    value={initialInvestment}
                    onChange={(e) => setInitialInvestment(e.target.value)}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Årlig yield (%)</FormLabel>
                  <Input
                    type="number"
                    placeholder="5% "
                    value={reinvestmentDividendYield}
                    onChange={(e) =>
                      setReinvestmentDividendYield(e.target.value)
                    }
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Antall år</FormLabel>
                  <Input
                    type="number"
                    placeholder="10"
                    value={years}
                    onChange={(e) => setYears(e.target.value)}
                    required
                  />
                </FormControl>
              </Stack>
              <Flex pt="5" pl="1">
                <Button type="submit" variant="primary">
                  Regn ut
                </Button>
              </Flex>
            </form>
          </Box>
        </SimpleGrid>
      </Stack>
      {reinvestmentResult && (
        <>
          <Stack spacing={{ base: "5", lg: "6" }}>
            <SimpleGrid
              columns={{ base: 2, md: 2 }}
              gap={{ base: "5", md: "6" }}
            >
              <Summary
                label={`Din formue om ${years} år`}
                value={formatNumber(reinvestmentResult) + " kr"}
              />
            </SimpleGrid>
          </Stack>
          <Stack spacing={{ base: "5", lg: "6" }}>
            <SimpleGrid
              columns={{ base: 2, md: 2 }}
              gap={{ base: "5", md: "6" }}
            >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>År</Th>
                    <Th>Reinvestert utbytte</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {reinvestedDividendAmounts.map(
                    ({ year, amount }: { year: number; amount: number }) => (
                      <Tr key={year}>
                        <Td>{year}</Td>
                        <Td>
                          {Number(amount).toLocaleString("no-NO", {
                            style: "currency",
                            currency: "NOK",
                          })}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th>Totalt</Th>
                    <Th>
                      {Number(
                        reinvestedDividendAmounts.reduce(
                          (total, { amount }) => total + amount,
                          0
                        )
                      ).toLocaleString("no-NO", {
                        style: "currency",
                        currency: "NOK",
                      })}
                    </Th>
                  </Tr>
                </Tfoot>
              </Table>
            </SimpleGrid>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default Kalkulator;
