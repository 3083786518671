import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  HStack,
  IconButton,
  Menu,
  Text,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  MenuDivider,
  MenuButton,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { FiList, FiBell, FiPieChart, FiSettings, FiGrid } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";

interface NavbarProps {
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  handleLogout: () => void;
}

const Navbar = ({ setIsLoggedIn, handleLogout }: NavbarProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("user");
    handleLogout();
    navigate("/login");
  };

  return (
    <Box as="nav" bg="bg-surface" boxShadow="sm">
      <Container py={{ base: "3", lg: "4" }}>
        <Flex justify="space-between">
          <HStack spacing="5">
            <ButtonGroup variant="ghost" spacing="1">
              <Link to={"/"}>
                <Button fontSize="lg" fontWeight="bold">
                  Utbyttebanken.no
                </Button>
              </Link>
              <Link to={"/"}>
                <Button
                  aria-current={location.pathname === "/" ? "page" : undefined}
                  leftIcon={<FiGrid fontSize="1.25rem" />}
                >
                  Portefølje
                </Button>
              </Link>

              <Link to={"/diversifisering"}>
                <Button
                  aria-current={
                    location.pathname === "/diversifisering"
                      ? "page"
                      : undefined
                  }
                  leftIcon={<FiPieChart fontSize="1.25rem" />}
                >
                  Diversifisering
                </Button>
              </Link>
              <Link to={encodeURI("/ønskeliste")}>
                <Button
                  aria-current={
                    location.pathname === encodeURI("/ønskeliste")
                      ? "page"
                      : undefined
                  }
                  leftIcon={<FiList fontSize="1.25rem" />}
                >
                  Ønskeliste
                </Button>
              </Link>
            </ButtonGroup>
          </HStack>
          <HStack spacing="4">
            <ButtonGroup variant="ghost" spacing="1">
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Innstillinger"
                  icon={<FiSettings fontSize="1.25rem" />}
                />
                <MenuList>
                  <Text fontSize="lg" fontWeight="medium" marginLeft="10px">
                    Innstillinger
                  </Text>
                  <MenuDivider />
                  <Link to={"/innstillinger"}>
                    <MenuItem>Min konto</MenuItem>
                  </Link>
                  <Link to={"/innstillinger"}>
                    <MenuItem>Mine varsler</MenuItem>
                  </Link>
                  <MenuItem onClick={handleLogoutClick}>Logg ut</MenuItem>
                </MenuList>
              </Menu>
              <Popover>
                <PopoverTrigger>
                  <IconButton
                    icon={<FiBell fontSize="1.25rem" />}
                    aria-label="Varsler"
                  />
                </PopoverTrigger>
                <Portal>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverHeader>
                      <Text fontSize="lg" fontWeight="medium">
                        Mine varsler
                      </Text>
                    </PopoverHeader>
                    <PopoverBody>Ingen nye varsler</PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </ButtonGroup>
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default Navbar;
