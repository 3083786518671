import React, { useEffect, useState } from "react";
import { Box, ChakraProvider, Container } from "@chakra-ui/react";
import { theme } from "./Theme";
import Navbar from "./Navbar";
import Portfolio from "./Portfolio";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Historikk from "./Historikk";
import Kalender from "./Kalender";
import Ønskeliste from "./Ønskeliste";
import Diversifisering from "./Diversifisering";
import Innstillinger from "./Innstillinger";
import Varslinger from "./Varslinger";
import { PortfolioProvider } from "./PortfolioContext";
import Kalkulator from "./Kalkulator";
import DevelopmentBanner from "./DevelopmentBanner";
import LandingPage from "./LandingPage";
import Priser from "./Priser";
import Spørsmål from "./Spørsmål";
import MarketingNavbar from "./MarketingNavbar";
import SignUp from "./SignUp";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import Utbytteliste from "./Utbytteliste";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("user");
  };

  return (
    <ChakraProvider theme={theme}>
      <PortfolioProvider>
        <Box as="section" height="100vh" overflowY="auto">
          <BrowserRouter>
            {isLoggedIn ? (
              <>
                <Navbar
                  setIsLoggedIn={setIsLoggedIn}
                  handleLogout={handleLogout}
                />
                <DevelopmentBanner />
                <Container
                  pt={{ base: "8", lg: "12" }}
                  pb={{ base: "12", lg: "24" }}
                >
                  <Routes>
                    <Route path="/" element={<Portfolio />} />
                    <Route path="/kalender" element={<Kalender />} />
                    <Route path="/historikk" element={<Historikk />} />
                    <Route path="/ønskeliste" element={<Ønskeliste />} />
                    <Route
                      path="/diversifisering"
                      element={<Diversifisering />}
                    />
                    <Route path="/kalkulator" element={<Kalkulator />} />
                    <Route path="/innstillinger" element={<Innstillinger />} />
                    <Route path="/varslinger" element={<Varslinger />} />
                  </Routes>
                </Container>
              </>
            ) : (
              <>
                <MarketingNavbar />
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/priser" element={<Priser />} />
                  <Route path="/utbytteliste" element={<Utbytteliste />} />
                  <Route path="/spørsmål" element={<Spørsmål />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route
                    path="/login"
                    element={<Login onLogin={handleLogin} />}
                  />

                  <Route path="/reset-password" element={<ResetPassword />} />
                </Routes>
              </>
            )}
          </BrowserRouter>
        </Box>
      </PortfolioProvider>
    </ChakraProvider>
  );
}

export default App;
