import { Box, Heading, Text } from "@chakra-ui/react";

const Spørsmål = () => {
  return (
    <Box>
      <Box as="section" bg="gray.800" pb={{ base: "12", md: "24" }}>
        <Box as="section" bg="gray.800" color="white" py="7.5rem">
          <Box
            maxW={{ base: "xl", md: "5xl" }}
            mx="auto"
            px={{ base: "6", md: "8" }}
          >
            <Box textAlign="center">
              <Heading
                as="h1"
                size="3xl"
                fontWeight="extrabold"
                maxW="48rem"
                mx="auto"
                lineHeight="1.2"
                letterSpacing="tight"
              >
                Spørsmål
              </Heading>
              <Text
                color="whiteAlpha.800"
                fontSize="xl"
                mt="4"
                maxW="xl"
                mx="auto"
              >
                Se Spørsmål
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Spørsmål;
