import {
  Box,
  Flex,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface StockApiResponse {
  symbol: string;
  name: string;
  // Include other fields from the API response if necessary
}

interface Stock {
  ticker: string;
  name: string;
  dividend: number; // or string if the dividend is formatted as a string
}

const Utbytteliste = () => {
  const [stocks, setStocks] = useState<Stock[]>([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      const API_ENDPOINT = "http://api.marketstack.com/v1/tickers";
      const ACCESS_KEY = "myKey"; // Replace with your actual access key
      const EXCHANGE = "XOSL"; // Exchange code for the Norwegian stock market
      const LIMIT = 500;

      try {
        const response = await fetch(
          `${API_ENDPOINT}?access_key=${ACCESS_KEY}&exchange=${EXCHANGE}&limit=${LIMIT}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();

        // Check if the 'data' array exists and is indeed an array
        if (Array.isArray(json.data)) {
          let stockData: Stock[] = json.data.map((item: StockApiResponse) => ({
            ticker: item.symbol.replace(".XOSL", ""), // Remove .XOSL from tickers
            name: item.name,
          }));

          // Sort the stock data alphabetically by name
          stockData.sort((a: Stock, b: Stock) => a.name.localeCompare(b.name));

          setStocks(stockData);

          // Cache the data with a timestamp
          localStorage.setItem("stockData", JSON.stringify(stockData));
          localStorage.setItem("stockDataTimestamp", Date.now().toString());
        } else {
          console.error(
            "Expected an array of stocks, but received:",
            json.data
          );
        }
      } catch (error) {
        console.error("Error fetching stock data:", error);
      }
    };

    // Check for cached data
    const cachedData = localStorage.getItem("stockData");

    if (cachedData) {
      setStocks(JSON.parse(cachedData));
    } else {
      fetchData();
    }
  }, []);

  return (
    <>
      <Box as="section" bg="gray.800" py="24">
        <Box
          maxW={{ base: "xl", md: "5xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
        >
          <Flex
            direction="column"
            align={{ base: "flex-start", md: "center" }}
            maxW="2xl"
            mx="auto"
          >
            <Heading
              as="h1"
              size="2xl"
              letterSpacing="tight"
              fontWeight="extrabold"
              textAlign={{ base: "start", md: "center" }}
              color="white"
            >
              Utbytteliste
            </Heading>
            <Text
              mt="4"
              fontSize="xl"
              textAlign={{ base: "left", md: "center" }}
              color="whiteAlpha.800"
            >
              Se utbytter for alle aksjer på Oslo Børs
            </Text>
          </Flex>
        </Box>
      </Box>
      <Box as="section" bg="gray.800" py="8">
        <Box
          maxW={{ base: "xl", md: "5xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
          color="black"
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Ticker</Th>
                <Th>Navn</Th>
                <Th>Utbytte</Th>
                <Th>Yield</Th>
                <Th>Frekvens</Th>
              </Tr>
            </Thead>
            <Tbody>
              {stocks.map((stock, index) => (
                <Tr key={index}>
                  <Td>{stock.ticker}</Td>
                  <Td>{stock.name}</Td>
                  <Td>NA</Td>
                  <Td>NA</Td>
                  <Td>NA</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box as="section" bg="gray.800" py="40"></Box>
      <Box as="section" bg="gray.800" py="40"></Box>
    </>
  );
};

export default Utbytteliste;
